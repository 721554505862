import { Map } from 'immutable';
import config from '../config';

import {
  SET_PAYMENT_PROVIDER, SET_PAYMENT_REF_DATA, SET_PAYMENT_DETAILS,
  SET_PAYMENT_METHOD, SET_PAYMENT_INTENT, RESET_PAYMENT_METHOD,
  KLARNA_SESSION_SUCCESS
} from '../actions/payment';

import {
  SET_BOOKING_LOCATION, RESET_BOOKING, RESERVE_BOOKING_SUCCESS,
  SET_BOOKING_SLOT
} from '../actions/booking';

export default function payment(state = Map(), action = null) {
  switch (action.type) {
    case SET_PAYMENT_PROVIDER:
      return state.set('paymentProvider', action.paymentProvider);

    case SET_PAYMENT_REF_DATA:
      return state.set('paymentRefData', action.paymentRefData);

    case SET_PAYMENT_METHOD:
      return state.set('paymentMethod', action.paymentMethod);

    case SET_PAYMENT_INTENT:
      return state.set('paymentIntent', action.paymentIntent);

    case RESERVE_BOOKING_SUCCESS:
      return action.confirmationMethod !== 'Payment'
        ? state.delete('paymentRequired').delete('paymentProvider')
        : state.set('paymentRequired', true);

    case RESET_PAYMENT_METHOD:
      return state.delete('paymentRefData')
        .delete('paymentMethod').delete('paymentIntent');

    case KLARNA_SESSION_SUCCESS:
      return state.set('klarnaSession', action.klarnaSession);

    case SET_PAYMENT_DETAILS:
      return state.set('discountVoucher', action.discountVoucher);

    case SET_BOOKING_SLOT:
      return state.delete('discountVoucher');

    case RESET_BOOKING:
      return state.clear();

    case SET_BOOKING_LOCATION:
      return config.mergeLocations ? state : state.clear();

    default:
      return state;
  }
}
