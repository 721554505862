import React from 'react';
import { useSelector } from 'react-redux';

const LoadingOverlay = () => {
  const loading = useSelector(state => state.app.get('loading'));

  if (!loading) {
    return null;
  }
  return (
    <div className="cb-loading-overlay">
      <div className="cb-spinner" />
    </div>
  );
};

export default LoadingOverlay;
