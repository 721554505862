import React, { Fragment } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { enableLocationSelection } from '../../config';
import { getPreviousStep, getCurrentIndex, getSteps } from '../../helpers/nav';
import { ChevronLeft } from './images';

const Nav = ({ intl, steps, previousStep, current, history }) => {
  const goBack = (ev) => {
    ev.preventDefault();
    history.push(previousStep);
  };

  const goToStep = (ev, step) => {
    ev.preventDefault();
    history.push(step);
  };

  return (
    <div className="cb-nav">
      {previousStep && (
        <a href="#" onClick={goBack} className="cb-nav-item back" title={intl.formatMessage({ id: 'back' })}>
          <ChevronLeft />
        </a>
      )}
      {steps.map((step, index) => {
        const stepNumber = index + 1;
        const previous = stepNumber < current;
        const className = classNames({
          'cb-nav-item': true,
          active: stepNumber === current,
          previous
        });
        return (
          <Fragment key={step}>
            {index !== 0 && <div className={`${className} item-separator`} />}
            {previous ? (
              <a href="#" onClick={(ev) => goToStep(ev, step)} className={className} title={stepNumber}>
                {stepNumber}
              </a>
            ) : (
              <div className={className}>{stepNumber}</div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const index = getCurrentIndex(state, props);
  const previousStep = getPreviousStep(state, props);

  return {
    previousStep,
    current: index + 1,
    steps: getSteps(state),
    hiddenSteps: enableLocationSelection() ? 1 : 0
  };
};

export default injectIntl(connect(mapStateToProps)(Nav));
