import * as Sentry from '@sentry/browser';

let sentryScope = null;
let sentryErrorHandler = null;

export function setSentryErrorHandler(handler) {
  sentryErrorHandler = handler;
}

export function initSentry(dsn) {
  const options = {
    transport: Sentry.makeFetchTransport,
    stackParser: Sentry.defaultStackParser
  };
  const client = new Sentry.BrowserClient({
    dsn,
    ...options,
    integrations: Sentry.getDefaultIntegrations(options)
  });
  sentryScope = new Sentry.Scope();
  sentryScope.setClient(client);
  client.init();
}

export function captureSentryException(error) {
  if (sentryScope) {
    sentryScope.captureException(error);
  } else if (typeof sentryErrorHandler === 'function') {
    sentryErrorHandler(error);
  }
}

export function errorLog(message, error) {
  console.error(message, error); // eslint-disable-line no-console

  if (error && error instanceof Error) {
    captureSentryException(error);
  }
}

export function handleError(error) {
  const message = error.response?.data
    ?? error.message;

  errorLog(message, error);
}
