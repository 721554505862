import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { setContainerWidth } from '../../actions/app';

const ViewportTracker = () => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(setContainerWidth());

    const viewportUpdated = debounce(() => {
      dispatch(setContainerWidth());
    }, 100);

    window.addEventListener('resize', viewportUpdated);
    window.addEventListener('orientationchange', viewportUpdated);

    return () => {
      window.removeEventListener('resize', viewportUpdated);
      window.removeEventListener('orientationchange', viewportUpdated);
    };
  }, []);

  return null;
};

export default ViewportTracker;
