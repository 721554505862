import UAParser from 'ua-parser-js';
import queryString from 'query-string';
import { addCss, addStyles } from './helpers/style';
import { errorLog } from './helpers/error-log';

const config = {
  id: undefined,
  org: undefined,
  loc: undefined,
  isMicrosite: false,
  partner: 'cliento',
  api: 'https://apibk.cliento.com',
  css: 'https://cliento.com/widget-v3/cliento.css',
  images: 'https://cliento-web.s3.eu-central-1.amazonaws.com/images',
  sentryDsn: 'SENTRY_DSN_PLACEHOLDER',
  pusherKey: 'PUSHER_KEY_PLACEHOLDER',
  pusherCluster: 'PUSHER_CLUSTER_PLACEHOLDER',
  enablePusher: true,
  mergeLocations: undefined,
  expandSummary: undefined,
  serviceSortOrder: undefined,
  serviceFilter: undefined,
  resourceFilter: undefined,
  resourceHash: undefined,
  minDate: undefined,
  maxDate: undefined,
  locale: 'sv',
  style: null,
  customer: null,
  onNavigate: null,
  onReserved: null,
  onCompleted: null,
  settings: undefined,
  customTexts: undefined,
  enableTracking: ['GTM', 'Meta']
};

export function multipleLocations() {
  const isArray = Array.isArray(config.id);
  const length = isArray && config.id.length;

  if (length > 1) {
    return true;
  }
  if (length === 1) {
    config.id = config.id[0];
  }
  return false;
}

export function enableLocationSelection() {
  return multipleLocations() && !config.mergeLocations;
}

function extractProperties() {
  const props = window.cbk ? window.cbk.p : null;
  if (props) {
    for (let i = 0; i < props.length; i += 1) {
      config[props[i][0]] = props[i][1];
    }
  }
}

function setDeviceConfig() {
  const parser = new UAParser();
  const device = parser.getDevice();

  config.isMobile = device.type === 'mobile';
}

function setBookingRef() {
  const query = queryString.parse(window.location.search);
  const bookingRef = query.cbk_ref;

  if (bookingRef && bookingRef.length > 0) {
    config.bookingRef = bookingRef;
  }
}

export function initialize() {
  extractProperties();
  setDeviceConfig();
  setBookingRef();

  if (config.css) {
    addCss(config.css);
  }

  try {
    addStyles(config.style);
  } catch (error) {
    errorLog('Could not add styles', error);
  }

  if (!document.getElementById('cliento-booking')) {
    errorLog('Could not render booking. No div tag with id "cliento-booking" found.');
  }
}

export function initStandaloneWidgetJs(standaloneWidgetJs) {
  const header = document.querySelector('#cliento-header');
  if (header && standaloneWidgetJs) {
    try {
      eval(standaloneWidgetJs); // eslint-disable-line no-eval
      extractProperties();
    } catch (error) {
      errorLog('Could not execute custom script', error);
    }
  }
}

export function initFollowUpBookingConfig(data) {
  return () => {
    const {
      stableId, customerName, customerPhoneNumber, customerEmail,
      personalIDNo, noBookingBefore, noBookingAfter
    } = data;

    config.id = stableId;
    config.customer = {
      name: customerName,
      email: customerEmail,
      phone: customerPhoneNumber,
      pno: personalIDNo
    };

    if (noBookingBefore) {
      config.minDate = noBookingBefore;
    }
    if (noBookingAfter) {
      config.maxDate = noBookingAfter;
    }
  };
}

export default config;
