export const sms = {
  reminderWithin: 'sms_reminderWithin'
};

export const web = {
  companyName: 'web_companyName',
  resourceLabel: 'web_resourceLabel',
  allowMultipleServices: 'web_allowMultipleServices',
  allowMultipleAddonServices: 'web_allowMultipleAddonServices',
  autoResourceSelection: 'web_autoResourceSelection',
  showAllAvailableSlots: 'web_showAllAvailableSlots',
  showOneSlotPerTime: 'web_showOneSlotPerTime',
  mergeDuplicateResources: 'web_mergeDuplicateResources',
  alwaysShowDescription: 'web_alwaysShowDescription',
  alwaysShowSummary: 'web_alwaysShowSummary',
  collapseGroups: 'web_collapseGroups',
  requirePNo: 'web_requirePNo',
  allowForeignPno: 'web_allowForeignPno',
  separateNameFields: 'web_separateNameFields',
  requireEmail: 'web_requireEmail',
  confirmPhone: 'web_confirmPhone',
  confirmEmail: 'web_confirmEmail',
  hideNotes: 'web_hideNotes',
  requireNotes: 'web_requireNotes',
  requireOrgNo: 'web_requireOrgNo',
  requireOrgName: 'web_requireOrgName',
  customMessageLabel: 'web_customMessageLabel',
  messageExtraInfo: 'web_messageExtraInfo',
  bookingExtraInfo: 'web_bookingExtraInfo',
  paymentTerms: 'web_paymentTerms',
  showTCBox: 'web_showTCBox',
  customTCBoxLabel: 'web_customTCBoxLabel',
  showExtraTCBox: 'web_showExtraTCBox',
  extraTCBoxLabel: 'web_extraTCBoxLabel',
  showExtraTCBoxInVerifyStep: 'web_showExtraTCBoxInVerifyStep',
  showExtraInfoOnConfirmPage: 'web_showExtraInfoOnConfirmPage',
  standaloneWidgetJs: 'web_standaloneWidgetJs',
  bookingMaxDaysInAdvance: 'web_bookingMaxDaysInAdvance',
  showResourceStep: 'web_showResourceStep',
  skipServiceStep: 'web_skipServiceStep',
  minimumAgeLimit: 'web_minimumAgeLimit',
  birthDateNotAfter: 'web_birthDateNotAfter',
  postConfirmRedirectDisabled: 'web_postConfirmRedirectDisabled',
  showUnmappedServices: 'web_showUnmappedServices',
  gotoNextAvailableTime: 'web_gotoNextAvailableTime',
  autoSelectFirstAvailableSlot: 'web_autoSelectFirstAvailableSlot',
  showAllowMarketing: 'web_showAllowMarketing',
  allowMarketingLabel: 'web_allowMarketingLabel',
  allowMarketingDefaultChecked: 'web_allowMarketingDefaultChecked',
  addressLine1: 'web_addressLine1',
  postCode: 'web_postCode',
  area: 'web_area'
};
