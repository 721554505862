import { createSelector } from 'reselect';
import { getFeature } from './settings';

export const PaymentOption = {
  Stripe: 'Stripe',
  Swish: 'Swish',
  Klarna: 'Klarna',
  PayOnSite: 'PayOnSite'
};

export const getPaymentEnabled = createSelector(
  state => state.settings,
  (settings) => {
    return getFeature(settings, 'EnableEcomPayments');
  }
);

export const getPaymentOptions = createSelector(
  state => state.booking.get('paymentProviders'),
  (paymentProviders) => {
    return Object.keys(PaymentOption)
      .filter(key => paymentProviders?.some(provider => key === provider));
  }
);

export const getKlarnaAvailable = createSelector(
  getPaymentOptions,
  state => state.payment.get('klarnaSession'),
  (paymentOptions, klarnaSession) => {
    return paymentOptions.includes(PaymentOption.Klarna)
      && klarnaSession?.paymentMethodCategories?.length > 0;
  }
);

export const getIsValidPaymentProvider = createSelector(
  state => state.payment.get('paymentProvider'),
  getKlarnaAvailable,
  (paymentProvider, klarnaAvailable) => {
    const klarnaDisabled = paymentProvider === PaymentOption.Klarna && !klarnaAvailable;

    return paymentProvider && !klarnaDisabled;
  }
);

export const getPaymentAmountExVat = createSelector(
  state => state.booking.get('saleItems'),
  (saleItems) => {
    if (!saleItems) {
      return 0;
    }
    return saleItems.reduce((total, { amount, vatAmount }) => {
      return total + amount - vatAmount;
    }, 0);
  }
);

export const getVatPctAmounts = createSelector(
  state => state.booking.get('saleItems'),
  (saleItems) => {
    const vatPctAmounts = {};
    saleItems?.forEach(({ vatPct, vatAmount }) => {
      // eslint-disable-next-line no-prototype-builtins
      if (vatPctAmounts.hasOwnProperty(vatPct)) {
        vatPctAmounts[vatPct] += vatAmount;
      } else {
        vatPctAmounts[vatPct] = vatAmount;
      }
    });
    return vatPctAmounts;
  }
);

function getSaleItemTotal(saleItems, filter) {
  return saleItems ? saleItems.filter(filter)
    .reduce((a, b) => a + b.saleAmount, 0) : 0;
}

export const getTotalAmount = createSelector(
  state => state.booking.get('saleItems'),
  saleItems => getSaleItemTotal(saleItems, i => i.itemType !== 'Discount')
);

export const getTotalDiscount = createSelector(
  state => state.booking.get('saleItems'),
  saleItems => getSaleItemTotal(saleItems, i => i.itemType === 'Discount')
);

export const getShowExVatInfo = createSelector(
  state => state.settings.get('pricesExVat'),
  state => getPaymentEnabled(state),
  (pricesExVat, paymentEnabled) => {
    return pricesExVat && paymentEnabled;
  }
);
