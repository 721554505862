import React from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { useKeydown, usePreventBodyScroll } from '../../helpers/hooks';
import { Close } from './images';

const Modal = ({ title = null, children, onClose, isPlainModal = false, size, modalClassName }) => {
  useKeydown(27, onClose);
  usePreventBodyScroll();

  const classNameContent = classNames({
    'cb-modal-plain-content': isPlainModal,
    'cb-modal-content': !isPlainModal,
    'cb-modal-content-small': size === 'small',
    'cb-modal-content-medium': size === 'medium',
    [modalClassName]: modalClassName
  });

  if (isPlainModal) {
    return createPortal((
      <div className="cb-modal-container">
        <div className={classNameContent}>
          <button type="button" className="cb-modal-plain-close" onClick={onClose}>
            <Close />
          </button>
          {children}
        </div>
      </div>
    ), document.body);
  }

  return createPortal((
    <div className="cb-modal-container">
      <div className={classNameContent}>
        <div className="cb-modal-header">
          {title && <h4 className="cb-modal-heading">{title}</h4>}

          {onClose && (
            <button type="button" className="cb-modal-close" onClick={onClose}>
              <Close />
            </button>
          )}
        </div>

        {children}
      </div>
    </div>
  ), document.body);
};

export default Modal;
