import { injectIntl } from 'react-intl';
import { isSet } from '../../helpers/strings';

const MINUTE_LIMIT = 120;

export function formattedDuration(intl, duration) {
  const useHours = duration > MINUTE_LIMIT;
  const hours = useHours ? Math.floor(duration / 60) : 0;
  const minutes = useHours ? duration % 60 : duration;
  const parts = [];

  if (hours > 0) {
    parts.push(intl.formatMessage({ id: 'durationHours' }, { hours }));
  }
  if (minutes > 0) {
    parts.push(intl.formatMessage({ id: 'durationMinutes' }, { minutes }));
  }
  return parts.join(' ');
}

export function formattedDurationRange(intl, duration, minDuration, maxDuration) {
  if (!isSet(duration) && !isSet(minDuration)) {
    return null;
  }
  if (minDuration !== maxDuration) {
    const onlyMinutes = minDuration < MINUTE_LIMIT && maxDuration < MINUTE_LIMIT;

    return onlyMinutes
      ? `${minDuration}-${formattedDuration(intl, maxDuration)}`
      : `${formattedDuration(intl, minDuration)} - ${formattedDuration(intl, maxDuration)}`;
  }
  return formattedDuration(intl, isSet(duration) ? duration : minDuration);
}

const Duration = ({ intl, duration, minDuration, maxDuration }) => {
  return formattedDurationRange(intl, duration, minDuration, maxDuration);
};

export default injectIntl(Duration);
