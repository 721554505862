/* eslint-disable key-spacing, quote-props */

const strings = {
  'select':                      'Velg',
  'continue':                    'Fortsett',
  'back':                        'Tilbake',
  'fromPrice':                   'Fra {price}',
  'free':                        'Gratis',
  'durationHours':               '{hours} t',
  'durationMinutes':             '{minutes} min',
  'slotDate':                    '{day} {date}',
  'slotTimeStartEnd':            'kl. {start} - {end}',
  'slotTimeStart':               'kl. {start}',
  'optional':                    'valgfritt',
  'yes':                         'Ja',
  'no':                          'Nei',

  'firstStep.heading':           'Bestill tid',
  'location.heading':            'Hvor vil du bestille?',

  'service.heading':             'Hva vil du bestille?',
  'service.addonHeading':        'Anbefalte alternativer',
  'service.addonServices':       'Legg til flere tjenester',
  'service.addonAddEdit':        'Legg til / endre',
  'service.add':                 'Legg til',
  'service.showMore':            'Vis mer',
  'service.showLess':            'Vis mindre',
  'service.notBookable':         'Kontakt oss for bestilling',
  'service.noServices':          'Det finnes ingen tjenester å bestille',
  'service.book':                'Bestill',
  'service.select':              'Velg',
  'service.selected':            'Valgt',
  'service.summaryHeading':      'Din bestilling',
  'service.edit':                'Endre',

  'resource.heading':            'Hvilken vil du bestille?',
  'resource.book':               'Bestill',
  'resource.select':             'Velg {label}',
  'resource.showAll':            'Vis alle',
  'resource.noResources':        'Det finns ingenting att boka',

  'calendar.heading':            'Velg en tid',
  'calendar.progress':           'Sjekker tilgjengelighet',
  'calendar.noSlots':            'Ingen tider',
  'calendar.week':               'Uke {week}',
  'calendar.today':              'I dag',
  'calendar.previous':           'Forrige uke',
  'calendar.next':               'Neste uke',
  'calendar.noneThisWeek':       'Fant ingen ledige tider denne uken',
  'calendar.noneThisPeriod':     'Fant ingen ledige tider i denne perioden',
  'calendar.nextAvailable':      'Neste ledige tid er på {nextAvailable}',
  'calendar.showAvailable':      'Vis ledige tider',
  'calendar.noneAtAll':          'Fant ingen ledige tider innen {duration}',
  'calendar.notAvailable':       'Tjenesten er ikke tilgjengelig',

  'customer.heading':            'Fyll ut opplysningene dine',
  'customer.name':               'Navn',
  'customer.firstName':          'Fornavn',
  'customer.lastName':           'Etternavn',
  'customer.email':              'E-post',
  'customer.confirmEmail':       'E-post (gjenta)',
  'customer.phone':              'Mobiltelefon',
  'customer.confirmPhone':       'Mobiltelefon (gjenta)',
  'customer.pno':                'Personnummer',
  'customer.disabledPno':        'Personnummer',
  'customer.foreignPno':         'Annet ID-nummer',
  'customer.orgNo':              'Org.nummer',
  'customer.orgName':            'Firma',
  'customer.vehicleRegNo':       'Reg.nummer',
  'customer.note':               'Melding',
  'customer.changeCard':         'Bytt kort',
  'customer.acceptTerms':        'Jeg godtar vilkårene',
  'customer.allowForeignPno':    'Jeg har ikke svensk personnummer',
  'customer.foreignPnoHelp':     'Skriv inn samordningsnummer eller reservenummer',
  'customer.orgOptional':        'Företagsuppgifter behöver inte anges om du bokar som privatperson',

  'verify.confirmAndPayHeading': 'Betal og bekreft bestilling',
  'verify.confirmHeading':       'Bekreft bestilling',
  'verify.confirmNoPinHeading':  'Stemmer opplysningene?',
  'verify.confirmPinHeading':    'Vi har sendt en PIN-kode til {phone}',
  'verify.confirmPinMessage':    'Oppgi PIN-koden for å fullføre bestillingen',
  'verify.confirmBooking':       'Fullfør bestilling',
  'verify.cancelOrEditBooking':  'Avbryt eller rediger bestillingen',
  'verify.confirmAndPay':        'Bestill og betal {amount}',
  'verify.beforeUnload':         'Your booking is not completed, are you sure you want to leave the page?',
  'verify.voucher':              'Rabattkode',

  'payment.providerHeading':     'Hvordan vil du betale?',
  'payment.providerSwish':       'Betal med Swish',
  'payment.klarnaLater':         'Betal med Klarna',
  'payment.providerCard':        'Betal med kort',
  'payment.providerNone':        'Gratis bestilling',
  'payment.providerOnSite':      'Betal på stedet',
  'payment.phoneNumber':         'Oppgi telefonnummer',
  'payment.cardDetails':         'Oppgi kortopplysninger',
  'payment.swishHeader':         'Åpne Vipps-appen',
  'payment.swishInfo':           'Åpne Vipps-appen og følg instruksjonene for å fullføre bestillingen.',
  'payment.swishButton':         'Åpne Vipps-appen',
  'payment.providerNoneInfo':    'Bestillingen er gratis, bekreft for å fullføre bestillingen.',
  'payment.providerOnSiteInfo':  'Betal på stedet, bekreft for å fullføre bestillingen.',

  'timer.remaining':             'Gjenstående tid: {time}',

  'expired.heading':             'Tiden har gått ut!',
  'expired.message':             'Tiden for å bekrefte bestillingen har gått ut! ' +
                                 'Gå tilbake for å gjøre en ny bestilling.',
  'expired.back':                'Gå tilbake',

  'confirmation.heading':        'Takk for bestillingen!',
  'confirmation.email':          'En bekreftelse er sendt til {email}.',
  'confirmation.reminder':       'En påminnelse sendes til {phone} {interval} timer før avtalen.',
  'confirmation.postInfo':       'Trykk på knappen for å fullføre bestillingen.',
  'confirmation.postButton':     'Fullfør bestilling',

  'summary.location':            'Sted',
  'summary.date':                'Dato',
  'summary.time':                'Tid',
  'summary.duration':            'Lengde',
  'summary.resource':            'Ressurs',
  'summary.service':             'Tjeneste',
  'summary.services':            'Tjenester',
  'summary.address':             'Adresse',
  'summary.customer':            'Din informasjon',
  'summary.vehicle':             'Kjøretøy',
  'summary.company':             'Firma',
  'summary.priceExVat':          'Pris ekskl. moms',
  'summary.price':               'Pris',
  'summary.discount':            'Rabatt',
  'summary.vat':                 'Moms {vatPct}%',
  'summary.total':               'Total',
  'summary.amountToPay':         'Å betale',
  'summary.amountPaid':          'Betalt',
  'summary.card':                'Kort',

  'validation.required':         'må fylles ut',
  'validation.phone':            'ugyldig mobilnummer',
  'validation.phoneHint':        'Nummeret må begynne med 0 eller landskode (f.eks. 0047 eller +47)',
  'validation.phoneMismatch':    'stemmer ikke overens',
  'validation.email':            'ugyldig e-postadresse',
  'validation.emailMismatch':    'stemmer ikke overens',
  'validation.pno':              'ugyldig personnummer',
  'validation.orgNo':            'ugyldig org.nummer',
  'validation.orgNoRequired':    'må fylles ut dersom firma fylles ut',
  'validation.minimumAge':       'du må være minst {minimumAge} år',
  'validation.birthDate':        'du må være født {birthDate} eller tidligere',

  'placeholder.name':            'For- og etternavn',
  'placeholder.firstName':       'Fornavn',
  'placeholder.lastName':        'Etternavn',
  'placeholder.email':           'E-post',
  'placeholder.confirmEmail':    'E-post (gjenta)',
  'placeholder.phone':           'Mobiltelefon',
  'placeholder.confirmPhone':    'Mobiltelefon (gjenta)',
  'placeholder.pno':             'Personnummer',
  'placeholder.disabledPno':     'Personnummer',
  'placeholder.foreignPno':      'Annet ID-nummer',
  'placeholder.orgNo':           'Org.nummer',
  'placeholder.orgName':         'Firma',
  'placeholder.vehicleRegNo':    'Reg.nummer',
  'placeholder.note':            'Melding',

  'error.unavailable':           'Kunne ikke finne kontoen. Kontroller lenken og prøv på nytt.',
  'error.maintenance':           'Vi uppdaterar systemet med nya funktioner. Vänligen kom tillbaka senare.',
  'error.unknown':               'Det har oppstått en uventet feil. Gå tilbake og prøv på nytt.',
  'error.notAllowed':            'Bestilling er ikke tillatt. Kontakt bestillingsstedet.',
  'error.slotTaken':             'Tiden er ikke lenger tilgjengelig. Gå tilbake og velg en ny tid.',
  'error.wrongPin':              'Feil SMS-kode angitt. Kontroller koden og prøv på nytt.',
  'error.payment':               'Betalingen var mislykket. Kontroller opplysningene og prøv på nytt.',
  'error.paymentDeclined':       'Betalingen ble avbrutt. Gå tilbake og prøv på nytt.',
  'error.paymentHeading':        'Betalingen var mislykket.',
  'error.paymentNA':             'Betaling er ikke tilgjengelig for den valgte ressursen.',
  'error.paymentRequiredNA':     'Tjenesten krever betaling, men ingen betalingsmåter er tilgjengelige.',
  'error.paymentProviderNA':     'Betalingsmåten er ikke tilgjengelig. Prøv en annen betalingsmåte.',
  'error.swishNotEnrolled':      'Nummeret er ikke tilknyttet Vipps. Kontroller opplysningene og prøv på nytt.',
  'error.swishAlreadyExists':    'Det finnes allerede en pågående Vipps-betaling. Avbryt betalingen i Vipps-appen og prøv på nytt.',
  'error.back':                  'Gå tilbake',

  'error.refNotValid':           'Bestillingsreferanse mangler eller er ikke gyldig. Kontroller lenken og prøv på nytt.',
  'error.refAlreadyUsed':        'Bestillingsreferansen din er allerede brukt til en bestilling.',
  'error.alreadyBookedPno':      'Det finnes allerede en bestilling for dette personnummeret.',
  'error.alreadyBookedMob':      'Det finnes allerede en bestilling for dette telefonnummeret.',

  'footer.exVatInfo':            '* Alle priser er ekskl. moms',
  'footer.privacy':              'Vi behandler dine personopplysninger sikkert. Les mer i vår <link>personvernpolicy</link>.',
  'footer.cliento':              'Bestillingssystem fra Cliento'
};

export default strings;
