import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import { addVoucherCode, removeVoucher } from '../../actions/payment';
import InputField from '../base/input-field';
import Error from '../base/error';
import Price from '../intl/price';
import { XMark } from '../base/images';

const voucherCode = value => value && value.toUpperCase().replace(/[^\w\d]/, '');

const VoucherForm = ({ discountVoucher, totalDiscount }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const onSubmit = ({ voucherCode }, form) => {
    setError(null);
    return dispatch(addVoucherCode(voucherCode))
      .then(() => form.reset())
      .catch(error => setError(error));
  };

  const onRemoveVoucher = () => {
    return dispatch(removeVoucher());
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, form, values }) => (
        <form className="cb-form" onSubmit={ev => handleSubmit(ev, form)}>
          <section className="cb-voucher-container">
            <div className="cb-label">
              <FormattedMessage id="verify.voucher" />
            </div>
            {discountVoucher ? (
              <div className="cb-voucher-label">
                {discountVoucher.code}
                <small><Price price={totalDiscount} /></small>
                <button type="button" onClick={onRemoveVoucher}>
                  <XMark />
                </button>
              </div>
            ) : (
              <div className="cb-voucher-form">
                <Field
                  type="text"
                  name="voucherCode"
                  component={InputField}
                  autoComplete="off"
                  placeholder="Ange rabattkod"
                  parse={voucherCode}
                />
                <button type="submit" className="cb-button cb-button-small" disabled={!values.voucherCode || submitting}>
                  Lägg till
                </button>
              </div>
            )}
          </section>
          {error && <Error error={error} />}
        </form>
      )}
    </Form>
  );
};

export default injectIntl(VoucherForm);
