/* eslint-disable key-spacing, quote-props */

const strings = {
  'select':                      'Välj',
  'continue':                    'Fortsätt',
  'back':                        'Tillbaka',
  'fromPrice':                   'Från {price}',
  'free':                        'Kostnadsfri',
  'durationHours':               '{hours} h',
  'durationMinutes':             '{minutes} min',
  'slotDate':                    '{day} {date}',
  'slotTimeStartEnd':            'kl. {start} - {end}',
  'slotTimeStart':               'kl. {start}',
  'optional':                    'valfritt',
  'yes':                         'Ja',
  'no':                          'Nej',

  'firstStep.heading':           'Boka tid',
  'location.heading':            'Var vill du boka?',

  'service.heading':             'Vad vill du boka?',
  'service.addonHeading':        'Rekommenderade tillval',
  'service.addonServices':       'Lägg till tjänster',
  'service.addonAddEdit':        'Lägg till / ändra',
  'service.add':                 'Lägg till',
  'service.showMore':            'Visa mer',
  'service.showLess':            'Visa mindre',
  'service.notBookable':         'Kontakta oss för bokning',
  'service.noServices':          'Det finns inga tjänster att boka',
  'service.book':                'Boka',
  'service.select':              'Välj',
  'service.selected':            'Vald',
  'service.summaryHeading':      'Din bokning',
  'service.edit':                'Ändra',

  'resource.heading':            'Vem vill du boka?',
  'resource.book':               'Boka',
  'resource.select':             'Välj {label}',
  'resource.showAll':            'Visa alla',
  'resource.noResources':        'Det finns ingenting att boka',

  'calendar.heading':            'Välj en tid',
  'calendar.progress':           'Kontrollerar tillgänglighet',
  'calendar.noSlots':            'Inga tider',
  'calendar.week':               'Vecka {week}',
  'calendar.today':              'Idag',
  'calendar.previous':           'Föregående vecka',
  'calendar.next':               'Nästa vecka',
  'calendar.noneThisWeek':       'Hittade inga lediga tider denna vecka',
  'calendar.noneThisPeriod':     'Hittade inga lediga tider under denna period',
  'calendar.nextAvailable':      'Nästa lediga tid är på {nextAvailable}',
  'calendar.showAvailable':      'Visa lediga tider',
  'calendar.noneAtAll':          'Hittade inga lediga tider inom {duration}',
  'calendar.autoSelectError':    'Tjänsten är inte tillgänglig',
  'calendar.remainingSlots':     '{remainingSlots} platser',
  'calendar.remainingSlot':      '{remainingSlots} plats',
  'calendar.fullyBooked':        'Fullbokad',
  'calendar.notAvailable':       'Ej tillgänglig',

  'customer.heading':            'Fyll i dina uppgifter',
  'customer.name':               'Namn',
  'customer.firstName':          'Förnamn',
  'customer.lastName':           'Efternamn',
  'customer.email':              'E-post',
  'customer.confirmEmail':       'E-post (igen)',
  'customer.phone':              'Mobiltelefon',
  'customer.confirmPhone':       'Mobiltelefon (igen)',
  'customer.pno':                'Personnummer',
  'customer.disabledPno':        'Personnummer',
  'customer.foreignPno':         'Annat ID-nummer',
  'customer.orgNo':              'Org.nummer',
  'customer.orgName':            'Företag',
  'customer.vehicleRegNo':       'Reg.nummer',
  'customer.note':               'Meddelande',
  'customer.changeCard':         'Byt kort',
  'customer.acceptTerms':        'Jag godkänner villkoren',
  'customer.allowForeignPno':    'Jag har inget svenskt personnummer',
  'customer.foreignPnoHelp':     'Skriv in ditt samordningsnummer eller reservnummer',
  'customer.orgOptional':        'Företagsuppgifter behöver inte anges om du bokar som privatperson',

  'verify.confirmAndPayHeading': 'Betala och bekräfta din bokning',
  'verify.confirmHeading':       'Bekräfta din bokning',
  'verify.confirmNoPinHeading':  'Stämmer uppgifterna?',
  'verify.confirmPinHeading':    'Vi har skickat en PIN-kod till {phone}',
  'verify.confirmPinMessage':    'Ange PIN-koden för att slutföra bokningen',
  'verify.confirmBooking':       'Slutför bokning',
  'verify.cancelOrEditBooking':  'Avbryt eller ändra bokning',
  'verify.confirmAndPay':        'Boka och betala {amount}',
  'verify.beforeUnload':         'Din bokning är inte slutförd, är du säker på att du vill lämna sidan?',
  'verify.voucher':              'Rabattkod',

  'payment.providerHeading':     'Hur vill du betala?',
  'payment.providerSwish':       'Betala med Swish',
  'payment.klarnaLater':         'Betala med Klarna',
  'payment.providerCard':        'Betala med kort',
  'payment.providerNone':        'Kostnadsfri bokning',
  'payment.providerOnSite':      'Betala på plats',
  'payment.phoneNumber':         'Ange ditt telefonnummer',
  'payment.cardDetails':         'Ange dina kortuppgifter',
  'payment.swishHeader':         'Öppna Swish-appen',
  'payment.swishInfo':           'Öppna Swish-appen och följ instruktionerna för att slutföra din bokning.',
  'payment.swishButton':         'Öppna Swish-appen',
  'payment.providerNoneInfo':    'Bokningen är kostnadsfri, bekräfta för att slutföra din bokning',
  'payment.providerOnSiteInfo':  'Betala på plats, bekräfta för att slutföra din bokning',

  'timer.remaining':             'Återstående tid: {time}',

  'expired.heading':             'Tiden har gått ut!',
  'expired.message':             'Tiden för att bekräfta din bokning har gått ut! ' +
                                 'Vänligen gå tillbaka för att göra en ny bokning.',
  'expired.back':                'Gå tillbaka',

  'confirmation.heading':        'Tack för din bokning!',
  'confirmation.email':          'En bekräftelse har skickats till {email}.',
  'confirmation.reminder':       'En påminnelse kommer skickas till {phone} {interval} timmar innan bokningen.',
  'confirmation.postInfo':       'Tryck på knappen för att slutföra din bokning.',
  'confirmation.postButton':     'Slutför bokning',

  'summary.location':            'Plats',
  'summary.date':                'Datum',
  'summary.time':                'Tid',
  'summary.duration':            'Längd',
  'summary.resource':            'Resurs',
  'summary.service':             'Tjänst',
  'summary.services':            'Tjänster',
  'summary.address':             'Adress',
  'summary.customer':            'Dina uppgifter',
  'summary.vehicle':             'Fordon',
  'summary.company':             'Företag',
  'summary.priceExVat':          'Pris ex. moms',
  'summary.price':               'Pris',
  'summary.discount':            'Rabatt',
  'summary.vat':                 'Moms {vatPct}%',
  'summary.total':               'Totalt',
  'summary.amountToPay':         'Att betala',
  'summary.amountPaid':          'Betalt',
  'summary.card':                'Kort',

  'validation.required':         'måste fyllas i',
  'validation.phone':            'ogiltigt mobilnummer',
  'validation.phoneHint':        'Numret måste börja med 0 eller landskod (t.ex. 0046 eller +46)',
  'validation.phoneMismatch':    'matchar inte',
  'validation.email':            'ogiltig e-postadress',
  'validation.emailMismatch':    'matchar inte',
  'validation.pno':              'ogiltigt personnummer',
  'validation.orgNo':            'ogiltigt org.nummer',
  'validation.orgNoRequired':    'måste fyllas i om företag är ifyllt',
  'validation.minimumAge':       'du måste vara minst {minimumAge} år',
  'validation.birthDate':        'du måste vara född {birthDate} eller tidigare',
  'validation.vehicleRegNo':     'ogiltigt reg.nummer',

  'placeholder.name':            'För- och efternamn',
  'placeholder.firstName':       'Förnamn',
  'placeholder.lastName':        'Efternamn',
  'placeholder.email':           'E-post',
  'placeholder.confirmEmail':    'E-post (igen)',
  'placeholder.phone':           'Mobiltelefon',
  'placeholder.confirmPhone':    'Mobiltelefon (igen)',
  'placeholder.pno':             'Personnummer',
  'placeholder.disabledPno':     'Personnummer',
  'placeholder.foreignPno':      'Annat ID-nummer',
  'placeholder.orgNo':           'Org.nummer',
  'placeholder.orgName':         'Företag',
  'placeholder.vehicleRegNo':    'Reg.nummer',
  'placeholder.note':            'Meddelande',

  'error.unavailable':           'Kunde inte hitta kontot. Kontrollera länken och försök igen.',
  'error.maintenance':           'Vi uppdaterar systemet med nya funktioner. Vänligen kom tillbaka senare.',
  'error.unknown':               'Ett oväntat fel inträffade. Gå tillbaka och försök igen.',
  'error.notAllowed':            'Bokning är inte tillåten. Kontakta bokningsstället.',
  'error.slotTaken':             'Tiden är inte längre tillgänglig. Gå tillbaka och välj en ny tid.',
  'error.wrongPin':              'Felaktig SMS-kod angavs. Kontrollera koden och försök igen.',
  'error.payment':               'Betalning misslyckades. Kontrollera uppgifterna och försök igen.',
  'error.paymentDeclined':       'Betalning avbröts. Gå tillbaka och försök igen.',
  'error.paymentHeading':        'Betalning misslyckades',
  'error.paymentNA':             'Betalning är inte tillgängligt för vald resurs.',
  'error.paymentRequiredNA':     'Tjänsten kräver betalning, men inga betalsätt finns tillgängliga.',
  'error.paymentProviderNA':     'Betalsättet är inte tillgängligt. Försök med ett annat betalsätt.',
  'error.swishNotEnrolled':      'Numret är inte kopplat till Swish. Kontrollera uppgifterna och försök igen.',
  'error.swishAlreadyExists':    'Det finns redan en pågående Swish-betalning. Avbryt betalningen i Swish-appen och försök igen.',
  'error.klarnaNotAvailable':    'Betalsättet är inte tillgängligt för det här köpet.',
  'error.klarnaDateNotAllowed':  'Klarna kan inte användas för betalning av bokningar som är mer än 28 dagar framåt i tiden.',
  'error.back':                  'Gå tillbaka',

  'error.refNotValid':           'Bokningsreferens saknas eller är inte giltig. Kontrollera länken och försök igen.',
  'error.refAlreadyUsed':        'Din bokningsreferens har redan använts för en bokning.',
  'error.alreadyBookedPno':      'Det finns redan en bokning för detta personnummer.',
  'error.alreadyBookedMob':      'Det finns redan en bokning för detta telefonnummer.',
  'error.alreadyBookedClass':    'Du är redan bokad på den här klassen.',

  'error.voucherNotValid':       'Rabattkoden är inte giltig',
  'error.voucherNotFound':       'Rabattkoden finns inte',
  'error.voucherMinSpend':       'Rabattkoden är inte giltig för köp under {amount}',
  'error.voucherBefore':         'Rabattkoden är inte giltig före {date}',
  'error.voucherAfter':          'Rabattkoden är inte giltig efter {date}',
  'error.voucherMaxRedeems':     'Rabattkoden är förbrukad',

  'footer.exVatInfo':            '* Alla priser är exkl. moms',
  'footer.privacy':              'Vi behandlar dina personuppgifter på ett säkert sätt. Läs mer i vår <link>personuppgiftspolicy</link>.',
  'footer.cliento':              'Bokningssystem från Cliento'
};

export default strings;
