import moment from 'moment';
import { getJson, postJson, prefixUrl, prefixBaseUrl, networkDelay } from '../helpers/network';
import { formatPhoneNumberE164 } from '../helpers/phone-number';
import { getFullPno, getLuhnNumber } from '../helpers/luhn-number';
import { fetchSettings, initSettings } from './settings';
import { handleCardAction, resetPaymentMethod, setPaymentRefData } from './payment';
import { fetchRefData, fetchMergedRefData, fetchRefDataSuccess } from './ref-data';
import { setLoading, resetLoading } from './app';
import config, { initFollowUpBookingConfig, multipleLocations } from '../config';
import { getMergedWebSettings, getPreference } from '../helpers/settings';
import { triggerCompletedEvent, triggerReservedEvent } from '../helpers/events';
import { web } from '../helpers/preference-keys';
import { debugLog } from '../helpers/debug-log';
import { errorLog, handleError } from '../helpers/error-log';
import { connectPusher, disconnectPusher, PAYMENT_SUCCESS, PAYMENT_ERROR } from '../helpers/pusher';
import history from '../helpers/history';
import { Step } from '../helpers/nav';

export const SET_BOOKING_LOCATION = 'SET_BOOKING_LOCATION';
export const SET_BOOKING_MULTIPLE_SERVICES = 'SET_BOOKING_MULTIPLE_SERVICES';
export const ADD_BOOKING_SERVICE = 'ADD_BOOKING_SERVICE';
export const REMOVE_BOOKING_SERVICE = 'REMOVE_BOOKING_SERVICE';
export const RESET_BOOKING_SERVICE = 'RESET_BOOKING_SERVICE';
export const CLEAR_ADDON_SERVICES = 'CLEAR_ADDON_SERVICES';
export const SET_BOOKING_RESOURCE = 'SET_BOOKING_RESOURCE';
export const SET_BOOKING_SLOT = 'SET_BOOKING_SLOT';
export const SET_BOOKING_CUSTOMER = 'SET_BOOKING_CUSTOMER';
export const SET_BOOKING_FOREIGN_PNO = 'SET_BOOKING_FOREIGN_PNO';
export const SET_BOOKING_TERMS_ACCEPTED = 'SET_BOOKING_TERMS_ACCEPTED';
export const SET_BOOKING_EXTRA_TERMS_ACCEPTED = 'SET_BOOKING_EXTRA_TERMS_ACCEPTED';
export const SET_BOOKING_ALLOW_MARKETING = 'SET_BOOKING_ALLOW_MARKETING';
export const SET_BOOKING_EXPIRED = 'SET_BOOKING_EXPIRED';
export const RESET_BOOKING = 'RESET_BOOKING';
export const RESERVE_BOOKING_SUCCESS = 'RESERVE_BOOKING_SUCCESS';
export const CONFIRM_BOOKING_SUCCESS = 'CONFIRM_BOOKING_SUCCESS';
export const CONFIRM_BOOKING_ERROR = 'CONFIRM_BOOKING_ERROR';
export const CLEAR_BOOKING_ERROR = 'CLEAR_BOOKING_ERROR';

export function setBookingLocation(location) {
  return (dispatch, getState) => {
    const { locations } = getState();
    const settings = location || locations.sortBy(l => l.get('order')).first();

    initSettings(settings);

    return dispatch({
      type: SET_BOOKING_LOCATION,
      location: settings
    });
  };
}

export function addBookingService(service) {
  return (dispatch, getState) => {
    const stableId = service.getIn(['attributes', 'locStableId']);
    if (config.mergeLocations && stableId && stableId !== config.stableId) {
      const { locations } = getState();
      const location = locations.get(stableId);
      if (location) {
        dispatch(setBookingLocation(location));
      }
    }

    return dispatch({
      type: ADD_BOOKING_SERVICE,
      service
    });
  };
}

export function removeBookingService(service) {
  return {
    type: REMOVE_BOOKING_SERVICE,
    service
  };
}

export function resetBookingService() {
  return {
    type: RESET_BOOKING_SERVICE
  };
}

export function clearAddonServices() {
  return {
    type: CLEAR_ADDON_SERVICES
  };
}

export function setBookingResource(resource) {
  return {
    type: SET_BOOKING_RESOURCE,
    resource
  };
}

export function setBookingSlot(slot) {
  return {
    type: SET_BOOKING_SLOT,
    slot
  };
}

export function setBookingCustomer(customer) {
  return {
    type: SET_BOOKING_CUSTOMER,
    customer
  };
}

export function setBookingUseForeignPno(useForeignPno) {
  return {
    type: SET_BOOKING_FOREIGN_PNO,
    useForeignPno
  };
}

export function setBookingTermsAccepted(termsAccepted) {
  return {
    type: SET_BOOKING_TERMS_ACCEPTED,
    termsAccepted
  };
}

export function setBookingExtraTermsAccepted(termsAccepted) {
  return {
    type: SET_BOOKING_EXTRA_TERMS_ACCEPTED,
    termsAccepted
  };
}

export function setBookingAllowMarketing(allowMarketing) {
  return {
    type: SET_BOOKING_ALLOW_MARKETING,
    allowMarketing
  };
}

export function setBookingExpired() {
  return {
    type: SET_BOOKING_EXPIRED
  };
}

export function resetBooking() {
  return {
    type: RESET_BOOKING
  };
}

function fetchFollowUpBookingConfig(ref) {
  return (dispatch) => {
    const url = prefixBaseUrl(`/follow-up-booking-config/${encodeURIComponent(ref)}`);

    dispatch(setLoading());
    return getJson(url)
      .then(({ data }) => {
        const { refData, ...values } = data;
        dispatch(initFollowUpBookingConfig(values));
        dispatch(fetchRefDataSuccess(refData));
      })
      .catch((error) => {
        handleError(error);
        dispatch(resetLoading());
        return Promise.reject(error);
      });
  };
}

export function fetchInitialData() {
  return (dispatch) => {
    // Follow up booking flow
    if (config.bookingRef) {
      return dispatch(fetchFollowUpBookingConfig(config.bookingRef))
        .then(() => dispatch(fetchSettings()))
        .then(() => dispatch(setBookingLocation()));
    }

    // Multiple location booking flow
    if (multipleLocations()) {
      const promises = config.id.map((id, index) => {
        return dispatch(fetchSettings(id, index));
      });
      return Promise.all(promises)
        .then(() => {
          if (config.mergeLocations) {
            dispatch(setBookingLocation());
            return dispatch(fetchMergedRefData());
          }
          return Promise.resolve();
        });
    }

    // Regular booking flow
    return Promise.all([
      dispatch(fetchSettings()),
      dispatch(fetchRefData())
    ]).then(() => dispatch(setBookingLocation()));
  };
}

function getCustomFieldValues(state) {
  const customer = state.booking.get('customer');
  const customFields = state.customFields.get('fields');

  if (!customFields || customFields.isEmpty()) {
    return null;
  }

  const fields = [];
  customFields.forEach((field) => {
    const { key, type, label } = field.toObject();
    const value = customer.custom && customer.custom[key];
    fields.push({ key, type, label, value });
  });
  return fields;
}

function getAllowMarketingChecked(state) {
  const webSettings = getMergedWebSettings(state);
  const allowMarketing = state.booking.get('allowMarketing');

  if (webSettings.showAllowMarketing) {
    return typeof allowMarketing === 'undefined'
      ? webSettings.allowMarketingDefaultChecked
      : allowMarketing;
  }
  return null;
}

function reserveBookingSuccess(data) {
  return (dispatch, getState) => {
    dispatch({
      type: RESERVE_BOOKING_SUCCESS,
      expiration: moment().add(5, 'minutes'),
      confirmationMethod: data.confirmationMethod,
      paymentAmountIncVat: data.paymentAmountIncVat,
      allowDiscountVoucher: data.allowDiscountVoucher,
      stripeDestAccountId: data.stripeDestAccountId,
      paymentProviders: data.paymentProviders,
      bookingUuid: data.bookingUuid,
      bookingRef: data.bookingRef,
      attributes: data.attributes,
      saleItems: data.saleItems
    });
    triggerReservedEvent(getState());
  };
}

export function reserveBooking() {
  return (dispatch, getState) => {
    const state = getState();
    const resource = state.booking.get('resource');
    const customer = state.booking.get('customer');
    const slot = state.booking.get('slot');
    const expiration = state.booking.get('expiration');
    const expired = state.booking.get('expired');

    if (expiration && !expired) {
      dispatch(resetLoading());
      return Promise.resolve();
    }

    const data = {
      slotKey: slot.key,
      reminderTypes: ['SMS'],
      name: customer.name,
      email: customer.email || '',
      orgName: customer.orgName,
      orgNo: customer.orgNo && getLuhnNumber(customer.orgNo),
      vehicleRegNo: customer.vehicleRegNo,
      personalNumber: customer.pno && getFullPno(customer.pno),
      phone: formatPhoneNumberE164(customer.phone),
      note: customer.note || '',
      bookedSpecificResource: !!resource,
      customFields: getCustomFieldValues(state),
      allowMarketing: getAllowMarketingChecked(state),
      fuuid: config.bookingRef
    };

    if (state.booking.get('useForeignPno')) {
      data.personalNumber = customer.foreignPno;
      data.foreignPno = true;
    }

    dispatch(setLoading());
    return postJson(prefixUrl('/booking/reserve'), data)
      .then(({ data }) => dispatch(reserveBookingSuccess(data)))
      .catch((error) => {
        handleError(error);
        dispatch(resetLoading());
        return Promise.reject(error);
      });
  };
}

function getBookingData(slotKey, pin, payment, paymentData) {
  const { paymentProvider, phoneNumber, klarnaAuthToken } = paymentData;

  if (payment.get('paymentRequired')) {
    switch (paymentProvider) {
      case 'Stripe': {
        const paymentMethod = payment.get('paymentMethod');
        const paymentIntent = payment.get('paymentIntent');

        return paymentIntent
          ? { stripePaymentIntentId: paymentIntent.id, slotKey, paymentProvider }
          : { stripePaymentMethodId: paymentMethod.id, slotKey, paymentProvider };
      }
      case 'Swish': {
        const swishPayerAlias = phoneNumber
          ? formatPhoneNumberE164(phoneNumber).replace('+', '')
          : null;

        return { slotKey, paymentProvider, swishPayerAlias };
      }
      case 'Klarna': {
        return { slotKey, paymentProvider, klarnaAuthToken };
      }
      case 'None':
      case 'PayOnSite': {
        return { slotKey, paymentProvider };
      }
    }
  }
  return { slotKey, pin };
}

function redirectAfterBookingSuccess() {
  return (dispatch, getState) => {
    const { booking, settings } = getState();
    const attributes = booking.get('attributes');
    const redirectDisabled = getPreference(settings, web.postConfirmRedirectDisabled);

    if (!redirectDisabled && attributes && attributes.postConfirmRedirectUrl) {
      window.location = attributes.postConfirmRedirectUrl;
    } else {
      history.push(Step.Confirmation);
      dispatch(resetLoading());
    }
  };
}

function confirmBookingSuccess(data) {
  return (dispatch, getState) => {
    dispatch({
      type: CONFIRM_BOOKING_SUCCESS,
      smsReminderEnabled: data.smsReminderEnabled,
      emailConfirmSent: data.emailConfirmSent,
      smsConfirmSent: data.smsConfirmSent,
      paymentProvider: data.paymentProvider,
      paymentStatus: data.paymentStatus,
      reference: data.reference
    });
    triggerCompletedEvent(getState());
  };
}

function confirmBookingError(error) {
  return {
    type: CONFIRM_BOOKING_ERROR,
    error
  };
}

export function clearBookingError() {
  return {
    type: CLEAR_BOOKING_ERROR
  };
}

function isSwishPayment(paymentData) {
  return paymentData && paymentData.paymentProvider === 'Swish';
}

let asyncPaymentPending = false;

function asyncPaymentSuccess(data) {
  return (dispatch) => {
    if (asyncPaymentPending) {
      asyncPaymentPending = false;
      dispatch(confirmBookingSuccess(data));
      dispatch(redirectAfterBookingSuccess());
      disconnectPusher();
    }
  };
}

function asyncPaymentError(data) {
  return (dispatch) => {
    if (asyncPaymentPending) {
      asyncPaymentPending = false;
      const error = new Error();
      error.response = { status: 402, data };
      errorLog('Async payment error', error);
      dispatch(confirmBookingError(error));
      dispatch(resetLoading());
      disconnectPusher();
    }
  };
}

function pusherCallback(event, data) {
  debugLog(`Pusher: ${event}`, data);

  return (dispatch) => {
    if (event === PAYMENT_SUCCESS) {
      dispatch(asyncPaymentSuccess(data));
    }
    if (event === PAYMENT_ERROR) {
      dispatch(asyncPaymentError(data));
    }
  };
}

function initAsyncPayment(booking) {
  return (dispatch) => {
    asyncPaymentPending = true;
    connectPusher(booking.get('bookingUuid'), (event, data) => {
      dispatch(pusherCallback(event, data));
    });
  };
}

function confirmBooking(pin, paymentData) {
  return (dispatch, getState) => {
    const { booking, payment } = getState();
    const slot = booking.get('slot');
    const bookingData = getBookingData(slot.key, pin, payment, paymentData);

    if (isSwishPayment(paymentData)) {
      dispatch(initAsyncPayment(booking));
    }

    return postJson(prefixUrl(bookingData.paymentProvider ? '/booking/pay' : '/booking/confirm'), bookingData)
      .then(({ data }) => {
        dispatch(confirmBookingSuccess(data));
      })
      .catch((error) => {
        if (error.response && error.response.status === 402) {
          const { requiresAction, clientSecret } = error.response.data;

          // Swish payments always return with 402
          if (isSwishPayment(paymentData)) {
            debugLog('Swish: Payment required', paymentData);
            dispatch(setPaymentRefData(error.response.data));
            return Promise.resolve();
          }

          // Stripe payments that require card verification
          if (requiresAction && clientSecret) {
            debugLog('Stripe: Verification required', paymentData);

            return dispatch(handleCardAction(clientSecret))
              .then(() => dispatch(confirmBooking(pin, paymentData)));
          }

          // All other payment errors
          dispatch(resetPaymentMethod());
        }

        dispatch(confirmBookingError(error));
        disconnectPusher();
        throw error;
      });
  };
}

export function confirmBookingWithDelay(pin, paymentData) {
  return (dispatch) => {
    dispatch(setLoading());
    return Promise.all([dispatch(confirmBooking(pin, paymentData)), networkDelay()])
      .then(() => {
        if (!isSwishPayment(paymentData)) {
          dispatch(redirectAfterBookingSuccess());
        }
      })
      .catch((error) => {
        handleError(error);
        dispatch(resetLoading());
        return Promise.reject(error);
      });
  };
}

export function fetchBookingStatus(paymentProvider) {
  return (dispatch, getState) => {
    const { booking, payment } = getState();
    const bookingUuid = booking.get('bookingUuid');
    const paymentRefData = payment.get('paymentRefData');
    const url = prefixUrl(`/booking/${bookingUuid}/status`);

    return postJson(url, { paymentProvider, ...paymentRefData })
      .then(({ data }) => {
        const { status, body } = data;

        if (status === 'Confirmed') {
          dispatch(asyncPaymentSuccess(body));
        }
        if (status === 'PaymentError') {
          dispatch(asyncPaymentError(body));
        }
      })
      .catch((error) => {
        if (error.response) {
          dispatch(asyncPaymentError(error));
          throw error;
        }
        debugLog('Failed to fetch booking status', error);
      });
  };
}
