import React, { useCallback, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAvailableAddonServiceIds, getAvailableAddonServices, getBookableServiceIds,
  getSelectedAddonServices
} from '../../helpers/booking';
import { web } from '../../helpers/preference-keys';
import { getPreference } from '../../helpers/settings';
import { addBookingService, clearAddonServices, removeBookingService } from '../../actions/booking';
import Modal from '../base/modal';
import ServiceSummaryServices from './service-summary-services';
import ServiceItem from './service-item';
import { CaretRight } from '../base/images';

const AddonService = ({ onContinue, onCancel }) => {
  const dispatch = useDispatch();
  const availableServiceIds = useSelector(getAvailableAddonServiceIds);
  const bookableServiceIds = useSelector(getBookableServiceIds);
  const availableAddonServices = useSelector(getAvailableAddonServices);
  const selectedAddonServices = useSelector(getSelectedAddonServices);
  const alwaysShowDescription = useSelector(state => getPreference(state.settings, web.alwaysShowDescription));
  const allowMultipleAddonServices = useSelector(state => getPreference(state.settings, web.allowMultipleAddonServices));

  const serviceSelected = useCallback((service) => {
    const serviceId = service.get('serviceId');
    const selected = selectedAddonServices?.has(serviceId);

    if (selected) {
      dispatch(removeBookingService(service));
    } else {
      if (!allowMultipleAddonServices) {
        dispatch(clearAddonServices());
      }
      dispatch(addBookingService(service));

      if (!allowMultipleAddonServices) {
        onContinue();
      }
    }
  }, [selectedAddonServices]);

  return (
    <Modal
      modalClassName="cb-modal-addon-services"
      title={<FormattedMessage id="service.summaryHeading" />}
      onClose={onCancel}
    >
      <ul className="cb-service-modal-summary">
        <ServiceSummaryServices isModal />
      </ul>

      {availableAddonServices.size > 0 && (
        <h4 className="cb-service-modal-addons-heading">
          <FormattedMessage id="service.addonHeading" />
        </h4>
      )}

      <div className="cb-service-list">
        {availableAddonServices.map((service) => {
          const serviceId = service.get('serviceId');

          return (
            <Fragment key={serviceId}>
              <ServiceItem
                key={serviceId}
                service={service}
                available={availableServiceIds.includes(serviceId)}
                bookable={bookableServiceIds.includes(serviceId)}
                multipleServices={allowMultipleAddonServices}
                alwaysShowDescription={alwaysShowDescription}
                onSelect={() => serviceSelected(service)}
              />
              <hr />
            </Fragment>
          );
        })}
      </div>

      <div className="cb-service-button-container">
        <button type="button" className="cb-button" onClick={onContinue}>
          <FormattedMessage id="continue" />
          <CaretRight className="right" />
        </button>
      </div>
    </Modal>
  );
};

export default AddonService;
