import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { removeBookingService } from '../../actions/booking';
import { getCurrentMappedServices } from '../../helpers/booking';
import { CloseSmall } from '../base/images';
import DurationAndPrice from './duration-and-price';

const ServiceSummaryServices = ({ mappedServices, isModal }) => {
  const dispatch = useDispatch();

  return (mappedServices && mappedServices.valueSeq().map((service) => {
    const { name, serviceId, addon } = service.toObject();

    const className = classNames({
      'cb-service-summary-services': !isModal,
      'cb-service-modal-summary-services': isModal,
      addon
    });

    return (
      <li className={className} key={serviceId}>
        {isModal && addon && (<hr />)}

        <div className="cb-service-summary-text">
          <span className="cb-service-summary-service">
            {addon && !isModal && '+ '}
            {name}
          </span>

          <div className="cb-service-summary-details">
            <DurationAndPrice service={service} />

            {addon && isModal && (
              <button
                type="button"
                className="cb-button cb-button-text"
                onClick={() => dispatch(removeBookingService(service))}
              >
                <CloseSmall />
              </button>
            )}
          </div>
        </div>
      </li>
    );
  }));
};

const mapStateToProps = (state) => {
  return {
    mappedServices: getCurrentMappedServices(state)
  };
};

export default withRouter(connect(mapStateToProps)(ServiceSummaryServices));
