import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ChevronRight } from '../base/images';
import BookingStatus from './booking-status';
import config from '../../config';
import Modal from '../base/modal';
import VerifyPrompt from './verify-prompt';

const PaymentProgress = ({ paymentProvider, paymentRefData }) => {
  const onOpenSwish = () => {
    const token = paymentRefData?.paymentRequestToken;
    window.location = token
      ? `swish://paymentrequest?token=${token}&callbackurl=`
      : 'swish://';
  };

  return (
    <Modal
      size="small"
      onClose={null}
    >
      <VerifyPrompt />
      <div className="cb-payment-progress">
        <img src={`${config.images}/swish.svg`} alt="Swish" />
        <h3><FormattedMessage id="payment.swishHeader" /></h3>
        <p>
          <FormattedMessage id="payment.swishInfo" />
        </p>
        {config.isMobile && (
          <button type="button" className="cb-button" onClick={onOpenSwish}>
            <FormattedMessage id="payment.swishButton" />
            <ChevronRight />
          </button>
        )}
        <BookingStatus paymentProvider={paymentProvider} />
      </div>
    </Modal>
  );
};

export default PaymentProgress;
