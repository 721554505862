/* eslint-disable react/button-has-type */
import React from 'react';
import classNames from 'classnames';

const ProgressButton = ({ progress, children, ...props }) => {
  const className = classNames({
    'cb-button': true,
    'cb-button-loading': progress
  });

  return (
    <button
      {...props}
      className={className}
    >
      {progress && (
        <div className="cb-button-loader-container">
          <div className="cb-button-loader" />
        </div>
      )}
      <div className={progress ? 'cb-button-content-progress' : 'cb-button-content'}>
        {children}
      </div>
    </button>
  );
};

export default ProgressButton;
