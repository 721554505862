import { List, Set } from 'immutable';
import { createSelector } from 'reselect';
import { getLanguageCode } from 'cliento-web-utils';
import { resourceFilter, serviceFilter, serviceGroupComparer } from './filter';
import {
  getAllServicesMapping, getMergedServiceMapping, getSelectedServicesMapping,
  getSimilarResources, hasMapping, isBookable, mergeSimilarResources
} from './resource-mapping';
import { getSelectedResourceId } from './resource';
import { web } from './preference-keys';
import config from '../config';

export function getResourceById(resources, resourceId) {
  return resources.find(r => r.get('id') === resourceId);
}

const getStandaloneWidgetJs = createSelector(
  state => state.settings,
  settings => settings.getIn(['prefs', web.standaloneWidgetJs])
);

const getShowUnmappedServices = createSelector(
  state => state.settings,
  settings => settings.getIn(['prefs', web.showUnmappedServices])
);

const getLocationLanguageCode = createSelector(
  state => state.settings,
  settings => getLanguageCode(settings.getIn(['prefs', 'locale']))
);

export const getFilteredResources = createSelector(
  state => getStandaloneWidgetJs(state),
  state => state.resources,
  (widgetJs, resources) => {
    return resources && resources.filter(resourceFilter);
  }
);

function shouldShowService(showUnmappedServices, vipResourceId, resources, resourceMappings, service) {
  if (config.mergeLocations || vipResourceId || showUnmappedServices) {
    return true;
  }
  const filteredResourceIds = resources && resources.map(r => r.get('id')).toArray();
  return hasMapping(resourceMappings, filteredResourceIds, service);
}

export const getFilteredServices = createSelector(
  state => getStandaloneWidgetJs(state),
  state => getShowUnmappedServices(state),
  state => state.settings.get('resourceId'),
  state => getFilteredResources(state),
  state => state.services,
  state => state.resourceMappings,
  (widgetJs, showUnmappedServices, vipResourceId, resources, services, resourceMappings) => {
    return services && services.filter((service) => {
      return shouldShowService(showUnmappedServices, vipResourceId, resources, resourceMappings, service)
        && serviceFilter(service);
    });
  }
);

export const getResourceSelectionEnabled = createSelector(
  state => state.settings,
  (settings) => {
    return !config.resourceHash && !config.mergeLocations
      && !settings.getIn(['prefs', web.autoResourceSelection])
      && !settings.getIn(['prefs', web.showResourceStep]);
  }
);

export const getAutoResourceSelection = createSelector(
  state => state.settings,
  settings => settings.getIn(['prefs', web.autoResourceSelection])
);

const getMergeDuplicateResources = createSelector(
  state => state.settings,
  settings => settings.getIn(['prefs', web.mergeDuplicateResources])
);

export const getSelectedResourceIds = createSelector(
  state => getFilteredResources(state),
  state => state.booking.get('resource'),
  state => getMergeDuplicateResources(state),
  (resources, resource, mergeDuplicates) => {
    if (!resource) {
      return null;
    }
    return mergeDuplicates
      ? getSimilarResources(resources, resource).map(r => r.get('id')).toArray()
      : [resource.get('id')];
  }
);

export const getCurrentResource = createSelector(
  state => getFilteredResources(state),
  state => state.booking.get('slot'),
  (resources, slot) => {
    return resources.find(r => slot && slot.resourceId === r.get('id'));
  }
);

export const getMappedResources = createSelector(
  state => getFilteredResources(state),
  state => getFilteredServices(state),
  state => state.booking.get('services'),
  state => state.resourceMappings,
  (resources, filteredServices, selectedServices, resourceMappings) => {
    return resources.map((resource) => {
      const mapping = selectedServices && !selectedServices.isEmpty()
        ? getSelectedServicesMapping(selectedServices, resourceMappings, resource)
        : getAllServicesMapping(filteredServices, resourceMappings, resource);
      return resource.merge(mapping);
    });
  }
);

export const getResourceList = createSelector(
  state => getMappedResources(state),
  state => getMergeDuplicateResources(state),
  (resources, mergeDuplicates) => {
    return mergeDuplicates
      ? mergeSimilarResources(resources)
      : resources;
  }
);

export const getSelectedResource = createSelector(
  state => getCurrentResource(state),
  state => getSelectedResourceIds(state),
  state => getResourceList(state),
  (currentResource, selectedResourceIds, resourceList) => {
    if (currentResource) {
      return currentResource;
    }
    const selectedResourceId = getSelectedResourceId(resourceList, selectedResourceIds);
    return resourceList.find(r => r.get('id') === selectedResourceId);
  }
);

export const getSelectedResourceName = createSelector(
  state => getSelectedResource(state),
  (selectedResource) => {
    return selectedResource?.get('name');
  }
);

export const filterAndGroupResources = createSelector(
  state => getResourceList(state),
  (resourceList) => {
    return resourceList
      .filter(r => r.get('available'))
      .groupBy(s => s.get('group'));
  }
);

function mapServices(services, resourceIds, resourceMappings) {
  return services && services.map((service) => {
    if (!resourceIds || resourceIds.length === 0) {
      return service;
    }
    const mapping = getMergedServiceMapping(resourceMappings, resourceIds, service);
    return service
      .delete('minPrice').delete('maxPrice')
      .delete('minDuration').delete('maxDuration')
      .merge(mapping);
  });
}

export const getAllMappedServices = createSelector(
  state => getFilteredServices(state),
  state => getSelectedResourceIds(state),
  state => state.resourceMappings,
  (services, selectedResourceIds, resourceMappings) => {
    return mapServices(services, selectedResourceIds, resourceMappings);
  }
);

export const getCurrentMappedServices = createSelector(
  state => state.booking.get('services'),
  state => getSelectedResourceIds(state),
  state => getCurrentResource(state),
  state => state.resourceMappings,
  (services, selectedResourceIds, resource, resourceMappings) => {
    const resourceIds = resource ? [resource.get('id')] : selectedResourceIds;
    return mapServices(services, resourceIds, resourceMappings);
  }
);

export const getAvailableResources = createSelector(
  state => state.booking.get('services'),
  state => getFilteredResources(state),
  state => state.resourceMappings,
  (selectedServices, resources, resourceMappings) => {
    if (!selectedServices || selectedServices.isEmpty()) {
      return resources;
    }
    return resources.filter((resource) => {
      return selectedServices.every((service) => {
        return hasMapping(resourceMappings, [resource.get('id')], service);
      });
    });
  }
);

const getCurrentResourceIds = createSelector(
  state => getAvailableResources(state),
  state => getSelectedResourceIds(state),
  (availableResources, selectedResourceIds) => {
    return selectedResourceIds || availableResources.map(r => r.get('id'));
  }
);

export const getSelectedServices = createSelector(
  state => state.booking.get('services'),
  services => services?.filter(s => !s.get('addon'))
);

const getSum = (sum, value) => {
  return value === null ? sum : value + sum;
};

const getServiceSum = (services, property) => {
  return services?.reduce((sum, srv) => getSum(sum, srv.get(property)), null);
};

export const getSelectedServicesProps = createSelector(
  state => state.booking.get('services'),
  state => state.resourceMappings,
  state => getSelectedResource(state),
  state => state.settings.get('resourceId'),
  (services, resourceMappings, resource, vipResourceId) => {
    if (vipResourceId) {
      return {
        price: getServiceSum(services, 'price'),
        duration: getServiceSum(services, 'serviceDuration'),
        priceFrom: services?.some(srv => srv.get('priceFrom'))
      };
    }
    if (resource) {
      return getSelectedServicesMapping(services, resourceMappings, resource);
    }
    return {
      minDuration: getServiceSum(services, 'minDuration'),
      maxDuration: getServiceSum(services, 'maxDuration'),
      minPrice: getServiceSum(services, 'minPrice'),
      maxPrice: getServiceSum(services, 'maxPrice'),
      priceFrom: services?.some(srv => srv.get('priceFrom'))
    };
  }
);

export const getTotalDuration = createSelector(
  getSelectedServicesProps,
  ({ duration, minDuration, maxDuration }) => {
    return duration || minDuration || maxDuration;
  });

export const getTotalPrice = createSelector(
  getSelectedServicesProps,
  ({ price, minPrice, maxPrice }) => {
    return price || minPrice || maxPrice;
  }
);

export const getSelectedAddonServices = createSelector(
  state => state.booking.get('services'),
  services => services?.filter(s => s.get('addon'))
);

export const getAvailableServiceIds = createSelector(
  state => getFilteredServices(state),
  state => state.resourceMappings,
  state => getCurrentResourceIds(state),
  state => state.settings.get('resourceId'),
  (services, resourceMappings, resourceIds, vipResourceId) => {
    return services.filter((service) => {
      if (vipResourceId || config.mergeLocations) {
        return true;
      }
      return hasMapping(resourceMappings, resourceIds, service);
    }).map(s => s.get('serviceId'));
  }
);

export const getBookableServiceIds = createSelector(
  state => getFilteredServices(state),
  state => state.resourceMappings,
  state => getCurrentResourceIds(state),
  state => state.settings.get('resourceId'),
  (services, resourceMappings, resourceIds, vipResourceId) => {
    return services.filter((service) => {
      return isBookable(resourceMappings, resourceIds, service, vipResourceId);
    }).map(s => s.get('serviceId'));
  }
);

export const getAvailableAddonServiceIds = createSelector(
  state => getSelectedServices(state),
  state => getBookableServiceIds(state),
  state => state.addonMappings,
  (selectedServices, bookableServiceIds, addonMappings) => {
    const addonSrvIds = selectedServices?.keySeq()
      .map(key => addonMappings.find(m => m.get('srvId') === key))
      .map(mapping => mapping?.get('addonSrvIds') || List());

    return Set(addonSrvIds?.flatten())
      .filter(id => bookableServiceIds.includes(id));
  }
);

const filterServices = createSelector(
  state => getAllMappedServices(state),
  state => getSelectedResourceIds(state),
  state => state.settings.get('resourceId'),
  state => state.resourceMappings,
  (mappedServices, selectedResourceIds, vipResourceId, resourceMappings) => {
    return mappedServices
      .filter((service) => {
        return vipResourceId || !selectedResourceIds ||
          hasMapping(resourceMappings, selectedResourceIds, service);
      });
  }
);

const sortGroupedServices = (groupedServices, locale) => {
  return config.mergeLocations
    ? groupedServices.sortBy((value, key) => key, (a, b) => serviceGroupComparer(a, b, locale))
    : groupedServices;
};

export const getGroupedServices = createSelector(
  state => filterServices(state),
  state => getLocationLanguageCode(state),
  (services, locale) => {
    const groupedServices = services.filter(s => !s.get('addon'))
      .groupBy(s => s.get('group'));

    return sortGroupedServices(groupedServices, locale);
  }
);

export const getAvailableAddonServices = createSelector(
  state => filterServices(state),
  state => getAvailableAddonServiceIds(state),
  state => getSelectedAddonServices(state),
  (services, availableAddonServiceIds, selectedAddonServices) => {
    const availableServices = services
      .filter(s => s.get('addon') && availableAddonServiceIds?.includes(s.get('serviceId')))
      .filter(s => selectedAddonServices && !selectedAddonServices.includes(s))
      .sortBy(s => s.get('name'));

    return availableServices;
  }
);

export const getSingleAvailableService = createSelector(
  state => filterServices(state),
  (services) => {
    return services.size === 1
      ? services.first()
      : null;
  }
);

export const getSingleAvailableResource = createSelector(
  state => getResourceList(state),
  (resources) => {
    return resources.size === 1
      ? resources.first()
      : null;
  }
);
