import { Map, fromJS } from 'immutable';
import config from '../config';
import { web } from '../helpers/preference-keys';
import { getPreference } from '../helpers/settings';

import {
  SET_BOOKING_LOCATION, CLEAR_ADDON_SERVICES,
  ADD_BOOKING_SERVICE, REMOVE_BOOKING_SERVICE, RESET_BOOKING_SERVICE,
  SET_BOOKING_RESOURCE, SET_BOOKING_SLOT, SET_BOOKING_CUSTOMER,
  SET_BOOKING_FOREIGN_PNO, SET_BOOKING_TERMS_ACCEPTED,
  SET_BOOKING_EXTRA_TERMS_ACCEPTED, SET_BOOKING_EXPIRED,
  SET_BOOKING_ALLOW_MARKETING,
  RESET_BOOKING, RESERVE_BOOKING_SUCCESS, CONFIRM_BOOKING_SUCCESS,
  CONFIRM_BOOKING_ERROR, CLEAR_BOOKING_ERROR
} from '../actions/booking';

import { FETCH_SLOTS_SUCCESS } from '../actions/slots';
import { SET_PAYMENT_DETAILS } from '../actions/payment';

export function getInitialBookingState(state, action) {
  const { location, resource, service } = action;
  const allowMultipleServices = getPreference(location, web.allowMultipleServices);

  return state.withMutations((map) => {
    map.clear();

    if (allowMultipleServices && !config.mergeLocations) {
      map.set('multipleServices', true);
    }
    if (resource) {
      map.set('resource', fromJS(resource));
    }
    if (service) {
      map.setIn(['services', service.serviceId], fromJS(service));
    }
  });
}

export default function booking(state = Map(), action = null) {
  switch (action.type) {
    case ADD_BOOKING_SERVICE: {
      const serviceId = action.service.get('serviceId');
      return state.delete('slot')
        .setIn(['services', serviceId], action.service);
    }

    case REMOVE_BOOKING_SERVICE: {
      const serviceId = action.service.get('serviceId');
      return state.delete('slot').deleteIn(['services', serviceId]);
    }

    case RESET_BOOKING_SERVICE:
      return state.delete('services').delete('slot');

    case CLEAR_ADDON_SERVICES: {
      const services = state.get('services')?.filter(s => !s.get('addon'));
      return state.set('services', services);
    }

    case SET_BOOKING_RESOURCE:
      return state.delete('slot')
        .set('resource', action.resource);

    case SET_BOOKING_SLOT:
      return state.delete('expired').delete('expiration')
        .delete('paymentAmountIncVat').delete('saleItems')
        .set('slot', action.slot);

    case SET_BOOKING_CUSTOMER:
      return state.set('customer', action.customer);

    case SET_BOOKING_FOREIGN_PNO:
      return state.set('useForeignPno', action.useForeignPno);

    case SET_BOOKING_TERMS_ACCEPTED:
      return state.set('termsAccepted', action.termsAccepted);

    case SET_BOOKING_EXTRA_TERMS_ACCEPTED:
      return state.set('extraTermsAccepted', action.termsAccepted);

    case SET_BOOKING_ALLOW_MARKETING:
      return state.set('allowMarketing', action.allowMarketing);

    case SET_BOOKING_EXPIRED:
      return state.set('expired', true);

    case FETCH_SLOTS_SUCCESS:
      return state
        .set('fromDate', action.fromDate)
        .set('toDate', action.toDate);

    case RESERVE_BOOKING_SUCCESS:
      return state.delete('expired')
        .set('confirmationMethod', action.confirmationMethod)
        .set('paymentAmountIncVat', action.paymentAmountIncVat)
        .set('allowDiscountVoucher', action.allowDiscountVoucher)
        .set('stripeDestAccountId', action.stripeDestAccountId)
        .set('paymentProviders', action.paymentProviders)
        .set('bookingUuid', action.bookingUuid)
        .set('bookingRef', action.bookingRef)
        .set('expiration', action.expiration)
        .set('attributes', action.attributes)
        .set('saleItems', action.saleItems);

    case CONFIRM_BOOKING_SUCCESS:
      return state
        .set('smsReminderEnabled', action.smsReminderEnabled)
        .set('emailConfirmSent', action.emailConfirmSent)
        .set('smsConfirmSent', action.smsConfirmSent)
        .set('paymentProvider', action.paymentProvider)
        .set('paymentStatus', action.paymentStatus)
        .set('reference', action.reference);

    case SET_PAYMENT_DETAILS:
      return state.set('paymentAmountIncVat', action.amount)
        .set('saleItems', action.saleItems);

    case CONFIRM_BOOKING_ERROR:
      return state.set('error', action.error);

    case CLEAR_BOOKING_ERROR:
      return state.delete('error');

    case RESET_BOOKING:
      return state.clear();

    case SET_BOOKING_LOCATION:
      return !config.mergeLocations
        ? getInitialBookingState(state, action)
        : state;

    default:
      return state;
  }
}
