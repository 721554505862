import React from 'react';
import { FormattedMessage } from 'react-intl';

const ExVatInfo = () => (
  <div className="cb-ex-vat-info">
    <FormattedMessage id="footer.exVatInfo" />
  </div>
);

export default ExVatInfo;
