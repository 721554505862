/* eslint-disable camelcase */
import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getWidgetData } from '../../helpers/klarna';
import { debugLog } from '../../helpers/debug-log';
import { errorLog } from '../../helpers/error-log';

class KlarnaForm extends Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
  }

  componentDidMount() {
    const { session } = this.props;
    this.instanceId = 'klarna-payment-widget';
    this.mounted = true;

    if (session) {
      this.loadWidget();
    }
  }

  componentDidUpdate(prevProps) {
    const { amount, selected } = this.props;
    const fullPriceUpdated = prevProps.amount !== amount;
    const selectedUpdated = !prevProps.selected && selected;

    if (selectedUpdated || fullPriceUpdated) {
      this.loadWidget();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  bookingDateAllowed = () => {
    const { slot } = this.props;
    return moment().add(28, 'days').isAfter(slot.date);
  };

  loadWidget = () => {
    const { available } = this.props;
    if (!available || !this.container.current) {
      return;
    }
    if (!this.bookingDateAllowed()) {
      return;
    }
    try {
      const widgetData = getWidgetData(this.props);
      debugLog('Klarna: Loading widget', widgetData);
      window.Klarna.Payments.load(
        {
          container: this.container.current,
          instance_id: this.instanceId
        },
        widgetData,
        this.widgetLoaded
      );
    } catch (error) {
      errorLog('Error loading Klarna widget', error);
      throw error;
    }
  };

  widgetLoaded = ({ show_form, error }) => {
    if (!this.mounted) {
      // Klarna widget will call this method 20 seconds after a successful payment
      // with { show_form: false } to make sure the widget is not displayed
      return;
    }
    if (!show_form) {
      if (error) {
        errorLog('Error loading Klarna widget', error);
      }
    }
  };

  onSubmit = () => {
    try {
      window.Klarna.Payments.authorize({
        instance_id: this.instanceId
      }, {}, this.paymentAuthorized);
    } catch (error) {
      errorLog('Error authorizing Klarna payment', error);
      throw error;
    }
  };

  paymentAuthorized = ({ approved, authorization_token, show_form, error }) => {
    const { onConfirm } = this.props;
    if (approved) {
      onConfirm({
        paymentProvider: 'Klarna',
        klarnaAuthToken: authorization_token
      });
    } else if (!show_form) {
      if (error) {
        this.error('Error authorizing Klarna payment', error);
      }
    }
  };

  render() {
    const { selected, available } = this.props;

    return (
      <>
        {selected && !available && (
          <div className="cb-error">
            <FormattedMessage id="error.klarnaNotAvailable" />
          </div>
        )}
        {selected && available && !this.bookingDateAllowed() && (
          <div className="cb-error">
            <FormattedMessage id="error.klarnaDateNotAllowed" />
          </div>
        )}
        {selected && <div className="cb-input-group" ref={this.container} />}
      </>
    );
  }
}

KlarnaForm.propTypes = {
  amount: PropTypes.number,
  available: PropTypes.bool,
  selected: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { payment, booking } = state;

  return {
    session: payment.get('klarnaSession'),
    paymentAmountIncVat: booking.get('paymentAmountIncVat'),
    saleItems: booking.get('saleItems'),
    slot: booking.get('slot')
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(KlarnaForm);
