import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getCalendarViewDates } from '../../helpers/calendar';
import { CaretLeft, CaretRight } from '../base/images';

const CalendarNav = ({ viewDate, dayCount, minDate, maxDate, navPrev, navNext, intl }) => {
  const { fromDate, toDate } = getCalendarViewDates(viewDate, dayCount);

  const previousDisabled = fromDate.isSameOrBefore(minDate, 'day');
  const nextDisabled = maxDate && toDate.isSameOrAfter(maxDate, 'day');
  const previousClass = previousDisabled ? 'cb-nav-item disabled' : 'cb-nav-item active';
  const nextClass = nextDisabled ? 'cb-nav-item disabled' : 'cb-nav-item active';
  const adjacentWeeks = fromDate?.week() !== toDate?.week();

  return (
    <div className="cb-calendar-nav">
      <span className="cb-calendar-prev">
        <a href="#" className={previousClass} onClick={navPrev} title={intl.formatMessage({ id: 'calendar.previous' })}>
          <CaretLeft />
        </a>
      </span>

      <span className="cb-calendar-label">
        <span className="cb-calendar-date">{fromDate.format('D MMM').replace('.', '')} - {toDate.format('D MMM').replace('.', '')}</span>{' | '}
        <span className="cb-calendar-week">
          <FormattedMessage
            id="calendar.week"
            values={{
              week: adjacentWeeks ?
                `${fromDate.format('W')}-${toDate.format('W')}`
                : fromDate.format('W')
            }}
          />
        </span>
      </span>

      <span className="cb-calendar-next">
        <a href="#" className={nextClass} onClick={navNext} title={intl.formatMessage({ id: 'calendar.next' })}>
          <CaretRight />
        </a>
      </span>
    </div>
  );
};

export default injectIntl(CalendarNav);
