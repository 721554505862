import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/da';
import 'intl/locale-data/jsonp/nb';
import 'intl/locale-data/jsonp/sv';

import React from 'react';
import { createRoot } from 'react-dom/client';
import moment from 'moment';
import 'moment/locale/da';
import 'moment/locale/nb';
import 'moment/locale/sv';

import './styles/standalone.scss';
import './styles/themes/default.scss';

import enStr from './locale/en';
import daStr from './locale/da';
import nbStr from './locale/nb';
import svStr from './locale/sv';
import config, { initialize } from './config';
import configureStore from './configure-store';
import { initSentry } from './helpers/error-log';
import { initTracking } from './helpers/tracking';
import App from './components/app';

if (!config.sentryDsn.includes('PLACEHOLDER')) {
  initSentry(config.sentryDsn);
}

export const renderWidget = () => {
  initialize();
  initTracking();
  moment.locale(config.locale);
  moment.relativeTimeThreshold('h', 50);

  const messages = { en: enStr, da: daStr, nb: nbStr, sv: svStr };
  const store = configureStore();

  return createRoot(document.getElementById('cliento-booking'))
    .render(<App messages={messages} store={store} />);
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => renderWidget());
} else {
  renderWidget();
}
