import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { handleError } from '../../helpers/error-log';
import { cardDescription } from '../../helpers/strings';
import { clearBookingError } from '../../actions/booking';
import { setPaymentMethod, createPaymentMethod } from '../../actions/payment';
import { appendStripeScript, createCardElement } from '../../helpers/stripe';

class StripeForm extends React.Component {
  state = {
    error: null,
    paymentMethod: this.props.paymentMethod
  };

  componentDidMount() {
    const { stripeKey, stripeDestAccountId } = this.props;

    appendStripeScript(stripeKey, stripeDestAccountId)
      .then(() => {
        if (!this.props.paymentMethod) {
          this.initCardElement(false);
        }
      });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.paymentMethod && this.props.paymentMethod) {
      this.setState({ paymentMethod: null });
      this.initCardElement(true);
    }
  }

  componentWillUnmount() {
    if (this.card) {
      this.card.destroy();
    }
  }

  onChange = ({ error }) => {
    this.setState({ error: error && error.message });
  };

  initCardElement = (focusWhenReady) => {
    this.card = createCardElement({
      element: '#cb-card',
      onChange: this.onChange,
      focusWhenReady
    });
  };

  onSubmit = () => {
    if (this.props.paymentMethod) {
      return Promise.resolve();
    }
    return this.props.createPaymentMethod(this.card)
      .catch((error) => {
        handleError(error);
        this.setState({ error: error.message });
        this.card.focus();
        throw error;
      });
  };

  onRemove = (ev) => {
    ev.preventDefault();
    this.props.resetPaymentMethod();
  };

  render() {
    const { error, paymentMethod } = this.state;

    return (
      <div className="cb-input-group">
        <label className="cb-label" htmlFor="cb-card">
          <span><FormattedMessage id="payment.cardDetails" /></span>
          {error && <span className="cb-label-error">{error}</span>}
        </label>
        <div id="cb-card" />
        {paymentMethod && (
          <div>
            {cardDescription(paymentMethod.card)}{' '}
            <a href="#" onClick={this.onRemove}><FormattedMessage id="customer.changeCard" /></a>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { settings, booking, payment } = state;

  return {
    stripeKey: settings.get('stripePublicKey'),
    stripeDestAccountId: booking.get('stripeDestAccountId'),
    paymentMethod: payment.get('paymentMethod')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPaymentMethod: (card) => {
      return dispatch(createPaymentMethod(card));
    },
    resetPaymentMethod: () => {
      dispatch(clearBookingError());
      dispatch(setPaymentMethod(null));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StripeForm);
