import { useCallback, useEffect, useRef } from 'react';

export const useKeydown = (key, onKeydown) => {
  const handleKeydown = useCallback(({ keyCode }) => {
    if (keyCode === key && typeof onKeydown === 'function') {
      onKeydown();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);
};

export const usePreventBodyScroll = () => {
  useEffect(() => {
    document.body.classList.add('cb-modal-open');

    return () => {
      document.body.classList.remove('cb-modal-open');
    };
  }, []);
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useValueChanged = (value, callback) => {
  const prevValue = usePrevious(value);
  useEffect(() => {
    if (value && prevValue && value !== prevValue) {
      callback(value);
    }
  }, [value]);
};
