import { createSelector } from 'reselect';
import { enableLocationSelection } from '../config';
import { getMergedWebSettings, getPreference } from './settings';
import { getSingleAvailableResource, getSingleAvailableService } from './booking';
import { web } from './preference-keys';

export const Step = {
  Location: 'location',
  Resource: 'resource',
  Service: 'service',
  Calendar: 'calendar',
  Customer: 'customer',
  Verify: 'verify',
  Confirmation: 'confirmation'
};

export function getDirection(prevStep, nextStep) {
  const steps = Object.values(Step);
  const nextIndex = steps.indexOf(nextStep);
  const prevIndex = steps.indexOf(prevStep);
  return nextIndex > prevIndex ? 'forward' : 'backward';
}

export function getStepFromLocation(location) {
  return location?.pathname?.replace('/', '');
}

export const getStep = (state, props) => props.match.params.step;

export const getIsVerifyStep = createSelector(
  getStep, (step) => step === Step.Verify
);

export const getIsConfirmationStep = createSelector(
  getStep, (step) => step === Step.Confirmation
);

export const getShowResourceStep = createSelector(
  state => getPreference(state.settings, web.showResourceStep),
  state => state.settings.get('resourceId'),
  state => getSingleAvailableResource(state),
  (showResourceStep, vipResourceId, singleAvailableResource) => {
    return showResourceStep && !vipResourceId && !singleAvailableResource;
  }
);

export const getShowServiceStep = createSelector(
  state => getPreference(state.settings, web.skipServiceStep),
  getSingleAvailableService,
  (skipServiceStep, singleAvailableService) => {
    return !skipServiceStep || !singleAvailableService;
  }
);

export const getSteps = createSelector(
  getShowResourceStep,
  getShowServiceStep,
  (showResourceStep, showServiceStep) => {
    const steps = [];
    if (enableLocationSelection()) {
      steps.push(Step.Location);
    }
    if (showResourceStep) {
      steps.push(Step.Resource);
    }
    if (showServiceStep) {
      steps.push(Step.Service);
    }
    return steps.concat([
      Step.Calendar,
      Step.Customer,
      Step.Verify
    ]);
  }
);

export const getFirstStep = createSelector(
  getSteps,
  (steps) => {
    return steps && steps[0];
  }
);

export const getCurrentIndex = createSelector(
  getSteps, getStep, getIsVerifyStep,
  (steps, step, isVerifyStep) => {
    const index = Math.max(steps.indexOf(step), 0);
    return isVerifyStep ? index - 1 : index;
  }
);

export const getPreviousStep = createSelector(
  getSteps,
  getCurrentIndex,
  getMergedWebSettings,
  (steps, index, { autoSelectFirstAvailableSlot }) => {
    const previousStep = index > 0 ? steps[index - 1] : null;
    const skipCalendarStep = previousStep === Step.Calendar && autoSelectFirstAvailableSlot;
    return skipCalendarStep ? Step.Service : previousStep;
  }
);

export const getNextStep = createSelector(
  getSteps, getCurrentIndex,
  (steps, index) => {
    return index < steps.length - 1 ? steps[index + 1] : null;
  }
);

export const getStepHeading = createSelector(
  getSteps, getCurrentIndex, getIsConfirmationStep,
  (steps, index, isConfirmation) => {
    const firstSteps = [Step.Resource, Step.Service];
    if (!isConfirmation && index === 0 && firstSteps.includes(steps[0])) {
      return 'firstStep.heading';
    }
    return `${isConfirmation ? Step.Confirmation : steps[index]}.heading`;
  }
);

export function scrollIntoView() {
  setTimeout(() => {
    const element = document.getElementById('cliento-booking');
    const { top } = element.getBoundingClientRect();
    if (top < 0) {
      window.scrollBy({ top: top - 20, behavior: 'smooth' });
    }
  }, 0);
}
