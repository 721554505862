import { getContainerWidth } from '../helpers/style';

export const SET_LOADING = 'SET_LOADING';
export const RESET_LOADING = 'RESET_LOADING';
export const SET_CONTAINER_WIDTH = 'SET_CONTAINER_WIDTH';

export function setLoading() {
  return {
    type: SET_LOADING
  };
}

export function resetLoading() {
  return {
    type: RESET_LOADING
  };
}

export function setContainerWidth() {
  return {
    type: SET_CONTAINER_WIDTH,
    containerWidth: getContainerWidth()
  };
}
