import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { required, phone, composeValidators } from '../../helpers/validation';
import { getIsValidPaymentProvider, getKlarnaAvailable, PaymentOption } from '../../helpers/payment';
import { errorLog } from '../../helpers/error-log';
import { toggleCheckboxWithLink } from '../../helpers/forms';
import { setBookingExtraTermsAccepted } from '../../actions/booking';
import InputField from '../base/input-field';
import StripeForm from '../payment/stripe-form';
import KlarnaForm from '../payment/klarna-form';
import { formattedPrice } from '../intl/price';
import Markdown from '../base/markdown';
import { CaretRight } from '../base/images';
import Error from '../base/error';
import Radio from '../base/radio';
import CheckBox from '../base/checkbox';
import config from '../../config';
import ProgressButton from '../base/button';

const VerifyForm = ({
  onSubmit, onClose, customer, useSmsVerification, paymentAmountIncVat, paymentOptions, webSettings,
  confirmError, paymentRequired, paymentTerms, paymentProvider, setPaymentProvider, intl, loading
}) => {
  const dispatch = useDispatch();
  const stripeSelected = paymentProvider === PaymentOption.Stripe;
  const swishSelected = paymentProvider === PaymentOption.Swish;
  const klarnaSelected = paymentProvider === PaymentOption.Klarna;
  const payOnSiteSelected = paymentProvider === PaymentOption.PayOnSite;
  const klarnaAvailable = useSelector(getKlarnaAvailable);
  const isValidPaymentProvider = useSelector(getIsValidPaymentProvider);
  const extraTermsAccepted = useSelector(state => state.booking.get('extraTermsAccepted'));
  const initialValues = { phoneNumber: customer.phone };
  let stripeForm = null;
  let klarnaForm = null;

  const { showTCBox, showExtraTCBox, extraTCBoxLabel, showExtraTCBoxInVerifyStep } = webSettings;
  const paymentMethodNotSelected = paymentRequired && !isValidPaymentProvider;
  const extraTermsNotAccepted = showTCBox && showExtraTCBox && showExtraTCBoxInVerifyStep && !extraTermsAccepted;
  const submitDisabled = paymentMethodNotSelected || extraTermsNotAccepted;

  const amount = formattedPrice(intl, paymentAmountIncVat, false, 2);
  const submitText = paymentAmountIncVat && paymentProvider !== PaymentOption.PayOnSite
    ? intl.formatMessage({ id: 'verify.confirmAndPay' }, { amount })
    : intl.formatMessage({ id: 'verify.confirmBooking' });

  const toggleExtraTermsAccepted = (ev) => {
    toggleCheckboxWithLink(ev,
      (termsAccepted) => dispatch(setBookingExtraTermsAccepted(termsAccepted)),
      extraTermsAccepted);
  };

  const preSubmit = (formData) => {
    const data = {
      paymentProvider,
      ...formData
    };
    if (stripeSelected && stripeForm) {
      return stripeForm.onSubmit()
        .then(() => onSubmit(data), (error) => {
          errorLog('Stripe form submit error', error);
        });
    }
    if (klarnaSelected && klarnaForm) {
      return klarnaForm.onSubmit();
    }
    return onSubmit(data);
  };

  return (
    <Form onSubmit={preSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting }) => (
        <form className="cb-form" onSubmit={handleSubmit}>
          {confirmError && <Error error={confirmError} useSmsVerification={useSmsVerification} />}
          {useSmsVerification && (
            <Field
              type="tel"
              name="code"
              label="&nbsp;"
              component={InputField}
              autoComplete="off"
              placeholder="PIN"
              validate={required}
              autoFocus="true"
            />
          )}
          {paymentRequired && !paymentAmountIncVat && (
            <section className="cb-payment-option">
              <h3>
                <span className="cb-payment-option-label">
                  <Radio checked />
                  <FormattedMessage id="payment.providerNone" />
                </span>
              </h3>
              <p><FormattedMessage id="payment.providerNoneInfo" /></p>
            </section>
          )}
          {paymentRequired && paymentAmountIncVat && paymentOptions.length === 0 ? (
            <Error error={{ message: intl.formatMessage({ id: 'error.paymentRequiredNA' }) }} />
          ) : null}
          {paymentRequired && paymentAmountIncVat && paymentOptions.includes(PaymentOption.Swish) ? (
            <section className="cb-payment-option">
              <h3 onClick={() => setPaymentProvider(PaymentOption.Swish)}>
                <span className="cb-payment-option-label">
                  <Radio checked={swishSelected} />
                  <FormattedMessage id="payment.providerSwish" />
                </span>

                <span className="cb-payment-provider">
                  <img src={`${config.images}/swish.svg`} alt="Swish" className="cb-swish" />
                </span>
              </h3>
              {swishSelected && (
                <Field
                  type="tel"
                  name="phoneNumber"
                  label={<FormattedMessage id="payment.phoneNumber" />}
                  component={InputField}
                  autoComplete="off"
                  placeholder=""
                  validate={composeValidators(required, phone)}
                />
              )}
            </section>
          ) : null}
          {paymentRequired && paymentAmountIncVat && paymentOptions.includes(PaymentOption.Stripe) ? (
            <section className="cb-payment-option">
              <h3 onClick={() => setPaymentProvider(PaymentOption.Stripe)}>
                <span className="cb-payment-option-label">
                  <Radio checked={stripeSelected} />
                  <FormattedMessage id="payment.providerCard" />
                </span>

                <span className="cb-payment-provider">
                  <img src={`${config.images}/mastercard.svg`} alt="Mastercard" className="cb-mastercard" />
                  <img src={`${config.images}/visa.svg`} alt="Visa" className="cb-visa" />
                  <img src={`${config.images}/amex.svg`} alt="American Express" className="cb-amex" />
                </span>
              </h3>
              {stripeSelected && (
                <StripeForm ref={(ref) => { stripeForm = ref; }} />
              )}
            </section>
          ) : null}
          {paymentRequired && paymentAmountIncVat && paymentOptions.includes(PaymentOption.Klarna) ? (
            <section className="cb-payment-option">
              <h3 onClick={() => setPaymentProvider(PaymentOption.Klarna)}>
                <span className="cb-payment-option-label">
                  <Radio checked={klarnaSelected} />
                  <FormattedMessage id="payment.klarnaLater" />
                </span>

                <span className="cb-payment-provider">
                  <img src={`${config.images}/klarna-pink.svg`} alt="Klarna" className="cb-klarna" />
                </span>
              </h3>
              <KlarnaForm
                onConfirm={onSubmit}
                selected={klarnaSelected}
                available={klarnaAvailable}
                amount={paymentAmountIncVat}
                ref={(ref) => { klarnaForm = ref; }}
              />
            </section>
          ) : null}
          {paymentRequired && paymentAmountIncVat && paymentOptions.includes(PaymentOption.PayOnSite) ? (
            <section className="cb-payment-option">
              <h3 onClick={() => setPaymentProvider(PaymentOption.PayOnSite)}>
                <span className="cb-payment-option-label">
                  <Radio checked={payOnSiteSelected} />
                  <FormattedMessage id="payment.providerOnSite" />
                </span>
              </h3>
            </section>
          ) : null}
          {showTCBox && showExtraTCBox && extraTCBoxLabel && showExtraTCBoxInVerifyStep && (
            <section>
              <div className="cb-checkbox-container cb-accept" onClick={toggleExtraTermsAccepted}>
                <CheckBox checked={extraTermsAccepted} />
                <label>
                  <Markdown source={extraTCBoxLabel} />
                </label>
              </div>
            </section>
          )}

          <div className="cb-modal-footer">
            <a href="#" className="cb-cancel" onClick={onClose}>
              <FormattedMessage id="verify.cancelOrEditBooking" />
            </a>

            <ProgressButton
              type="submit"
              disabled={submitting || submitDisabled}
              progress={submitting || loading}
            >
              {submitText}
              <CaretRight className="right" />
            </ProgressButton>
          </div>

          {paymentRequired && paymentTerms && (
            <Markdown source={paymentTerms} className="cb-payment-terms" />
          )}
        </form>
      )}
    </Form>
  );
};

export default injectIntl(VerifyForm);
