import React from 'react';
import { useSelector } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { createPortal } from 'react-dom';
import { getSelectedServicesProps } from '../../helpers/booking';
import Price from '../intl/price';
import { isBrowser } from '../../env';

const ServiceCartFloating = ({ onClick, selectedServices, intl }) => {
  const selectedServicesProps = useSelector(getSelectedServicesProps);
  const containerWidth = useSelector(state => state.app.get('containerWidth'));
  const summarySizeText = selectedServices.size === 1 ? 'summary.service' : 'summary.services';

  return isBrowser ? createPortal((
    <div className="cb-service-cart-container">
      <div className="cb-service-cart" style={{ maxWidth: containerWidth }}>
        <div className="cb-service-cart-summary">
          <p>
            {selectedServices.size}{' '}
            {intl.formatMessage({ id: summarySizeText }).toLowerCase()}
          </p>
          <p>
            <strong>
              <Price {...selectedServicesProps} />
            </strong>
          </p>
        </div>

        <button
          type="button"
          className="cb-button"
          onClick={onClick}
        >
          <FormattedMessage id="continue" />
        </button>
      </div>
    </div>
  ), document.body) : null;
};

export default injectIntl(ServiceCartFloating);
