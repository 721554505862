import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { resetBooking } from '../actions/booking';
import { CaretRight } from './base/images';
import { formatPhoneNumber } from '../helpers/phone-number';
import { getFirstStep } from '../helpers/nav';
import { getTotalDuration } from '../helpers/booking';
import { getMergedWebSettings, getPreference } from '../helpers/settings';
import { sms, web } from '../helpers/preference-keys';
import Summary from './summary';
import DateTime from './intl/date-time';
import Markdown from './base/markdown';

class Confirmation extends React.Component {
  componentDidMount() {
    document.body.classList.add('cb-confirmation');
  }

  componentWillUnmount() {
    document.body.classList.remove('cb-confirmation');

    this.props.resetBooking();
  }

  render() {
    const {
      slot, customer, companyName, emailConfirmSent, allowSmsNow, smsReminderEnabled, webSettings,
      smsReminderWithin, postConfirmBtnUrl, firstStep, totalDuration
    } = this.props;

    const { bookingExtraInfo, showExtraInfoOnConfirmPage } = webSettings;
    const showExtraInfo = showExtraInfoOnConfirmPage && bookingExtraInfo;
    const { autoSelectFirstAvailableSlot } = webSettings;
    const showDateTime = slot && !autoSelectFirstAvailableSlot;
    const { phone, email } = customer || {};
    const hasTotalDuration = totalDuration > 0;

    if (!slot) {
      return <Redirect to={firstStep} />;
    }

    return (
      <div className="cb-confirmation-container">

        <div className="cb-confirmation-preamble">
          {showDateTime && (
            <p className="cb-confirmation-date-time">
              <DateTime slot={slot} showEndTime={hasTotalDuration} />
            </p>
          )}

          <p className="cb-confirmation-company">
            {companyName}
          </p>
        </div>

        {postConfirmBtnUrl && (
          <div className="cb-confirmation-info">
            <p>
              <FormattedMessage id="confirmation.postInfo" />
            </p>
            <a href={postConfirmBtnUrl} className="cb-button">
              <FormattedMessage id="confirmation.postButton" />
              <CaretRight className="right" />
            </a>
          </div>
        )}

        <Summary showCustomer />

        <div className="cb-confirmation-details">
          {emailConfirmSent && email && (
            <p>
              <FormattedMessage
                id="confirmation.email"
                values={{ email: <strong>{email}</strong> }}
              />
            </p>
          )}
          {allowSmsNow && smsReminderEnabled && (
            <p>
              <FormattedMessage
                id="confirmation.reminder"
                values={{
                  phone: <strong>{formatPhoneNumber(phone)}</strong>,
                  interval: smsReminderWithin
                }}
              />
            </p>
          )}
          {showExtraInfo && <Markdown className="cb-extra-info" source={bookingExtraInfo} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { booking, settings } = state;
  const attributes = booking.get('attributes');

  return {
    slot: booking.get('slot'),
    customer: booking.get('customer'),
    companyName: getPreference(settings, web.companyName),
    allowSmsNow: getPreference(settings, 'allowSmsNow'),
    smsReminderEnabled: booking.get('smsReminderEnabled'),
    emailConfirmSent: booking.get('emailConfirmSent'),
    smsReminderWithin: getPreference(settings, sms.reminderWithin),
    postConfirmBtnUrl: attributes && attributes.postConfirmBtnUrl,
    webSettings: getMergedWebSettings(state),
    totalDuration: getTotalDuration(state),
    firstStep: getFirstStep(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetBooking: () => dispatch(resetBooking())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
