import moment from 'moment';
import { fromJS, List, Map } from 'immutable';
import { createSelector } from 'reselect';

function flattenSlots(resourceSlots) {
  return resourceSlots && resourceSlots.length > 0
    ? resourceSlots.map(s => s.slots).reduce((a, b) => a.concat(b))
    : [];
}

export function getFirstSlot(resourceSlots) {
  const slots = flattenSlots(resourceSlots);
  return slots.length > 0 ? slots[0] : null;
}

export function mapResourceSlots(resourceSlots, fromDate, toDate) {
  const slots = flattenSlots(resourceSlots);
  const groupedSlots = fromJS(slots).groupBy(s => s.get('date'));

  return Map().withMutations((days) => {
    const currentDate = fromDate.clone();
    while (currentDate < toDate) {
      const groupName = currentDate.format('YYYY-MM-DD');
      const slots = List().withMutations((list) => {
        if (groupedSlots.has(groupName)) {
          groupedSlots.get(groupName).forEach((slot) => {
            const parts = slot.get('time').split(':');
            list.push(slot.set('time', `${parts[0]}:${parts[1]}`));
          });
        }
      });
      days.set(currentDate.clone(), slots.sortBy(s => s.get('time')));
      currentDate.add(1, 'd');
    }
  });
}

export function filterSlots(slots) {
  return slots.filter((slot, index, array) => {
    return array.map(({ time }) => time).indexOf(slot.time) === index;
  });
}

export const getCalendarDayCount = createSelector(
  state => state.app.get('containerWidth'),
  (containerWidth) => {
    return containerWidth < 500 ? 3 : 7;
  }
);

export function getCalendarViewDates(viewDate, dayCount) {
  const fromDate = moment(viewDate).startOf(dayCount === 7 ? 'isoWeek' : 'day');
  const toDate = moment(fromDate).add(dayCount - 1, 'd').endOf('day');

  return { fromDate, toDate };
}
