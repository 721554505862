import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirst } from '../../helpers/strings';
import { fetchAndSelectFirstSlotWithDelay, fetchSlots, resetSlots } from '../../actions/slots';
import {
  getFilteredResources, getMappedResources, getSelectedResourceIds, getResourceById,
  getResourceSelectionEnabled, getSingleAvailableService
} from '../../helpers/booking';
import { filterSlots, getCalendarDayCount, getCalendarViewDates } from '../../helpers/calendar';
import { addBookingService, setBookingSlot } from '../../actions/booking';
import { getNextStep, getFirstStep, getShowServiceStep } from '../../helpers/nav';
import { getMergedWebSettings, getPreference, getShouldFilterSlots } from '../../helpers/settings';
import { web } from '../../helpers/preference-keys';
import config from '../../config';
import Nav from '../base/nav';
import { ChevronRight } from '../base/images';
import CalendarNav from './calendar-nav';
import CalendarSlot from './calendar-slot';
import CalendarProgress from './calendar-progress';
import LoadingOverlay from '../base/loading-overlay';
import Error from '../base/error';

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    const minDate = moment.max(moment().startOf('day'), moment(config.minDate));
    const maxDate = config.maxDate ? moment(config.maxDate) : null;
    const viewDate = props.fromDate ? moment(props.fromDate) : minDate;

    this.state = {
      viewDate,
      today: moment(),
      minDate,
      maxDate,
      error: null
    };
  }

  componentDidMount() {
    const { days, services, showServiceStep, singleAvailableService } = this.props;
    const hasSelectedServices = services && !services.isEmpty();

    if (days) {
      this.props.resetSlots();
    }
    if (!showServiceStep && singleAvailableService && !hasSelectedServices) {
      this.props.addBookingService(singleAvailableService);
    }
    this.fetchSlots();
  }

  componentDidUpdate(prevProps) {
    const {
      selectedResourceIds, services, days, dayCount, nextAvailable,
      allDaysEmpty, gotoNextAvailableTime
    } = this.props;
    const resourceChanged = prevProps.selectedResourceIds !== selectedResourceIds;
    const servicesChanged = prevProps.services !== services;
    const dayCountChanged = prevProps.dayCount !== dayCount;
    const isFirstLoad = !prevProps.days && days;

    if (resourceChanged || servicesChanged || dayCountChanged) {
      this.fetchSlots();
    }
    if (isFirstLoad && allDaysEmpty && nextAvailable && gotoNextAvailableTime) {
      this.nextAvailable();
    }
  }

  navPrev = (ev) => {
    ev.preventDefault();
    const { dayCount } = this.props;
    this.setState(({ viewDate }) => ({
      viewDate: moment(viewDate).subtract(dayCount, 'd')
    }), this.fetchSlots);
  };

  navNext = (ev) => {
    ev.preventDefault();
    const { dayCount } = this.props;
    this.setState(({ viewDate }) => ({
      viewDate: moment(viewDate).add(dayCount, 'd')
    }), this.fetchSlots);
  };

  nextAvailable = (ev) => {
    ev?.preventDefault();
    const { dayCount, nextAvailable } = this.props;
    const { fromDate } = getCalendarViewDates(nextAvailable, dayCount);

    this.setState({ viewDate: fromDate }, this.fetchSlots);
  };

  fetchSlots = (props) => {
    const { viewDate } = this.state;
    const { dayCount, services, selectedResourceIds, webSettings } = props || this.props;
    const { autoSelectFirstAvailableSlot } = webSettings;

    if (services && !services.isEmpty()) {
      const serviceIds = services.map(s => s.get('serviceId'));
      const { fromDate, toDate } = getCalendarViewDates(viewDate, dayCount);

      if (autoSelectFirstAvailableSlot) {
        this.props.fetchAndSelectFirstSlotWithDelay(serviceIds, selectedResourceIds)
          .then(() => this.props.history.push(this.props.nextStep), error => this.setState({ error }));
      } else {
        this.props.fetchSlots(serviceIds, selectedResourceIds, fromDate, toDate)
          .then(() => this.setState({ error: null }), error => this.setState({ error }));
      }
    }
  };

  slotClick = (ev, slot) => {
    ev.preventDefault();
    this.props.setBookingSlot(slot);
    this.props.history.push(this.props.nextStep);
  };

  render() {
    const { viewDate, today, minDate, maxDate, error } = this.state;
    const {
      services, mappedResources, selectedResourceIds, shouldFilterSlots, resourceSelectionEnabled,
      days, allDaysEmpty, fromDate, toDate, nextAvailable, bookingMaxDaysInAdvance, firstStep,
      webSettings: { autoSelectFirstAvailableSlot }, showServiceStep, dayCount, selectedSlot
    } = this.props;
    const singleResource = mappedResources.count(r => r.get('available')) === 1;
    const showExtended = resourceSelectionEnabled && !selectedResourceIds && !singleResource && !shouldFilterSlots;
    const hasSelectedServices = services && !services.isEmpty();
    const isWeekView = dayCount === 7;
    const adjacentWeeks = fromDate?.week() !== toDate?.week() && !isWeekView;

    if (!hasSelectedServices && showServiceStep) {
      return <Redirect to={firstStep} />;
    }
    if (autoSelectFirstAvailableSlot) {
      return <CalendarProgress error={error} />;
    }

    return (
      <>
        <div className="cb-nav-container">
          <Route path="/:step?" component={Nav} />
          <CalendarNav
            viewDate={viewDate}
            dayCount={dayCount}
            minDate={minDate}
            maxDate={maxDate}
            navPrev={this.navPrev}
            navNext={this.navNext}
          />
        </div>
        {error ? (
          <Error error={error} />
        ) : (
          <div className="cb-calendar-days">
            <LoadingOverlay />
            {days && days.entrySeq().map(([date, slots]) => {
              const isToday = date.isSame(today, 'day');
              const isBeforeMin = date.isBefore(minDate, 'day');
              const isAfterMax = maxDate && date.isAfter(maxDate, 'day');
              const disabled = isBeforeMin || isAfterMax;
              const className = classNames({
                'cb-calendar-day': true,
                'cb-calendar-day-first': date.weekday() === 0 && adjacentWeeks,
                today: date.isSame(today, 'day'),
                disabled
              });
              const filteredSlots = shouldFilterSlots
                ? filterSlots(slots.toJS())
                : slots.toJS();

              return (
                <div className={className} key={date.valueOf()}>
                  <div className={isToday ? 'cb-day-header today' : 'cb-day-header'}>
                    {isToday
                      ? <FormattedMessage id="calendar.today" />
                      : capitalizeFirst(date.format('ddd').replace('.', ''))}
                    <span className="cb-date">{date.format('D')}</span>
                  </div>
                  <div className="cb-calendar-items">
                    {!disabled && filteredSlots.length > 0 ? filteredSlots.map(slot => (
                      <CalendarSlot
                        {...slot}
                        key={slot.key}
                        onSelect={ev => this.slotClick(ev, slot)}
                        selected={slot.key === selectedSlot?.key}
                        resource={getResourceById(mappedResources, slot.resourceId)}
                        showExtended={showExtended}
                      />
                    )) : (
                      <p className="cb-calendar-day-empty">
                        {!allDaysEmpty && <FormattedMessage id="calendar.noSlots" />}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
            {allDaysEmpty && (
              <div className="cb-calendar-days-empty">
                {nextAvailable ? (
                  <p>
                    <FormattedMessage id={isWeekView ? 'calendar.noneThisWeek' : 'calendar.noneThisPeriod'} /><br />
                    <FormattedMessage
                      id="calendar.nextAvailable"
                      values={{ nextAvailable: moment(nextAvailable).format('dddd D MMMM') }}
                    /><br />
                    <a href="#" onClick={this.nextAvailable}>
                      <FormattedMessage id="calendar.showAvailable" />
                      <ChevronRight />
                    </a>
                  </p>
                ) : (
                  <p>
                    <FormattedMessage
                      id="calendar.noneAtAll"
                      values={{ duration: moment.duration(bookingMaxDaysInAdvance, 'day').humanize() }}
                    />
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { booking, settings, slots } = state;
  const days = slots.get('days');

  return {
    resources: getFilteredResources(state),
    mappedResources: getMappedResources(state),
    selectedResourceIds: getSelectedResourceIds(state),
    resourceSelectionEnabled: getResourceSelectionEnabled(state),
    shouldFilterSlots: getShouldFilterSlots(state),
    bookingMaxDaysInAdvance: getPreference(settings, web.bookingMaxDaysInAdvance),
    gotoNextAvailableTime: getPreference(settings, web.gotoNextAvailableTime),
    singleAvailableService: getSingleAvailableService(state),
    showServiceStep: getShowServiceStep(state),
    webSettings: getMergedWebSettings(state),
    fromDate: booking.get('fromDate'),
    toDate: booking.get('toDate'),
    services: booking.get('services'),
    selectedSlot: booking.get('slot'),
    days,
    dayCount: getCalendarDayCount(state),
    allDaysEmpty: days && days.every(d => d.isEmpty()),
    nextAvailable: slots.get('nextAvailable'),
    firstStep: getFirstStep(state),
    nextStep: getNextStep(state, props)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSlots: (serviceIds, resourceIds, fromDate, toDate) => {
      return dispatch(fetchSlots(serviceIds, resourceIds, fromDate, toDate));
    },
    fetchAndSelectFirstSlotWithDelay: (serviceIds, resourceIds) => {
      return dispatch(fetchAndSelectFirstSlotWithDelay(serviceIds, resourceIds));
    },
    resetSlots: () => {
      dispatch(resetSlots());
    },
    setBookingSlot: (slot) => {
      dispatch(setBookingSlot(slot));
    },
    addBookingService: (service) => {
      dispatch(addBookingService(service));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
