import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getSelectMessage } from '../../helpers/resource';
import { setBookingResource } from '../../actions/booking';
import { getResourceSelectionEnabled, getSelectedResourceName } from '../../helpers/booking';
import { getPreference } from '../../helpers/settings';
import { web } from '../../helpers/preference-keys';
import { CaretDownSmall } from '../base/images';
import Modal from '../base/modal';
import ResourceSelector from './resource-selector';

class ResourceSelectorContainer extends React.Component {
  state = {
    isResourceModal: false
  };

  showResourceModal = () => {
    this.setState({ isResourceModal: true });
  };

  hideResourceModal = () => {
    this.setState({ isResourceModal: false });
  };

  render() {
    const { isResourceModal } = this.state;
    const { resourceSelectionEnabled, selectedResourceName, resourceLabel, intl } = this.props;
    const selectMessage = getSelectMessage(intl, resourceLabel);

    if (!resourceSelectionEnabled) {
      return null;
    }

    return (
      <>
        {isResourceModal && (
          <Modal
            isPlainModal
            size="medium"
            onClose={this.hideResourceModal}
          >
            <ResourceSelector onSelect={this.hideResourceModal} />
          </Modal>
        )}

        <div className="cb-resource-selector-trigger" onClick={this.showResourceModal}>
          {selectedResourceName ?? selectMessage}
          <CaretDownSmall />
        </div>

        <ResourceSelector />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { settings } = state;

  return {
    selectedResourceName: getSelectedResourceName(state),
    resourceSelectionEnabled: getResourceSelectionEnabled(state),
    resourceLabel: getPreference(settings, web.resourceLabel)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBookingResource: (resource) => {
      dispatch(setBookingResource(resource));
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ResourceSelectorContainer));
