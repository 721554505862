import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const PrivacyPolicy = () => {
  const stableId = useSelector(state => state.settings.get('stableId'));
  const link = (content) => (
    <a href={`https://cliento.com/gdpr/${stableId}`} target="_blank">{content}</a>
  );

  return (
    <div className="cb-privacy">
      <FormattedMessage id="footer.privacy" values={{ link }} />
    </div>
  );
};

export default PrivacyPolicy;
