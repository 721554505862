import React from 'react';
import { FormattedMessage } from 'react-intl';

class ResourceItem extends React.Component {
  render() {
    const { resource, onSelect } = this.props;
    const { id, name } = resource.toObject();

    return (
      <div id={`cb-resource-${id}`} className="cb-resource-item" onClick={onSelect}>
        <div className="cb-resource-item-header">
          <strong>{name}</strong>

          <div className="cb-resource-item-cta">
            <button type="button" className="cb-button cb-button-success-outline">

              <FormattedMessage id="resource.book" />

            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ResourceItem;
