import React from 'react';
import { isSet } from '../../helpers/strings';
import Price from '../intl/price';
import Duration from '../intl/duration';

const DurationAndPrice = ({ service }) => {
  const {
    webShowDuration, minDuration, maxDuration, serviceDuration,
    webShowPrice, minPrice, maxPrice, price, priceFrom
  } = service.toObject();

  return (
    <>
      {webShowDuration && isSet(serviceDuration) && serviceDuration > 0 && (
        <span><Duration duration={serviceDuration} /></span>
      )}
      {isSet(maxDuration) && maxDuration > 0 && (
        <span><Duration minDuration={minDuration} maxDuration={maxDuration} /></span>
      )}

      {webShowPrice && isSet(price) && (
        <span><Price price={price} priceFrom={priceFrom} /></span>
      )}
      {isSet(minPrice) && (
        <span><Price minPrice={minPrice} maxPrice={maxPrice} priceFrom={priceFrom} /></span>
      )}
    </>
  );
};

export default DurationAndPrice;
