import React from 'react';

const svgProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  fill: 'transparent',
  height: 16,
  width: 16
};

export const ChevronUp = () => (
  <svg {...svgProps} viewBox="0 0 24 24">
    <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
  </svg>
);

export const ChevronDown = () => (
  <svg {...svgProps} viewBox="0 0 24 24">
    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
  </svg>
);

export const ChevronLeft = () => (
  <svg {...svgProps} viewBox="0 0 24 24" className="left">
    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
  </svg>
);

export const ChevronRight = () => (
  <svg {...svgProps} viewBox="0 0 24 24" className="right">
    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
  </svg>
);

export const CheckMark = () => (
  <svg {...svgProps} viewBox="0 0 24 24">
    <path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z" />
  </svg>
);

export const CheckMarkThankYou = () => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="transparent" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20.3215" r="20" fill="#DAF7E7" />
    <path d="M10.3896 20.6658L17.7746 28.7832L29.6119 11.8601" stroke="#5ACD8D" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const XMark = () => (
  <svg {...svgProps} viewBox="0 0 320 512">
    <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
  </svg>
);

export const Close = () => (
  <svg width="22" height="23" viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.93505 17.0897L9.70364 11.3212L3.93505 5.55258C3.76808 5.37969 3.67568 5.14814 3.67777 4.90779C3.67986 4.66745 3.77626 4.43753 3.94622 4.26758C4.11618 4.09762 4.34609 4.00121 4.58644 3.99913C4.82678 3.99704 5.05833 4.08943 5.23122 4.25641L10.9998 10.025L16.7684 4.25641C16.8529 4.16886 16.9541 4.09903 17.0659 4.05098C17.1778 4.00294 17.2981 3.97765 17.4198 3.9766C17.5415 3.97554 17.6622 3.99873 17.7748 4.04482C17.8875 4.09091 17.9898 4.15898 18.0759 4.24505C18.162 4.33112 18.23 4.43346 18.2761 4.54612C18.3222 4.65877 18.3454 4.77948 18.3444 4.90119C18.3433 5.02291 18.318 5.14319 18.27 5.25503C18.2219 5.36687 18.1521 5.46802 18.0646 5.55258L12.296 11.3212L18.0646 17.0897C18.1521 17.1743 18.2219 17.2755 18.27 17.3873C18.318 17.4991 18.3433 17.6194 18.3444 17.7411C18.3454 17.8628 18.3222 17.9835 18.2761 18.0962C18.23 18.2089 18.162 18.3112 18.0759 18.3973C17.9898 18.4833 17.8875 18.5514 17.7748 18.5975C17.6622 18.6436 17.5415 18.6668 17.4198 18.6657C17.2981 18.6647 17.1778 18.6394 17.0659 18.5913C16.9541 18.5433 16.8529 18.4735 16.7684 18.3859L10.9998 12.6173L5.23122 18.3859C5.05833 18.5529 4.82678 18.6453 4.58644 18.6432C4.34609 18.6411 4.11618 18.5447 3.94622 18.3747C3.77626 18.2048 3.67986 17.9749 3.67777 17.7345C3.67568 17.4942 3.76808 17.2626 3.93505 17.0897Z" />
  </svg>
);

export const CloseSmall = () => (
  <svg className="lineart" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 7L7 17M7 7L17 17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CaretUpSmall = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1663 7.74428C11.3914 7.51931 11.6965 7.39293 12.0147 7.39293C12.3329 7.39293 12.6381 7.51931 12.8631 7.74428L17.6631 12.5443C17.8817 12.7706 18.0027 13.0737 18 13.3884C17.9972 13.703 17.871 14.004 17.6485 14.2265C17.426 14.4489 17.1251 14.5752 16.8104 14.5779C16.4958 14.5806 16.1927 14.4597 15.9663 14.2411L12.0147 10.2895L8.06315 14.2411C7.95245 14.3557 7.82004 14.4471 7.67363 14.51C7.52723 14.5729 7.36976 14.606 7.21043 14.6074C7.05109 14.6088 6.89308 14.5784 6.7456 14.5181C6.59813 14.4577 6.46414 14.3686 6.35147 14.2559C6.2388 14.1433 6.1497 14.0093 6.08936 13.8618C6.02902 13.7143 5.99866 13.5563 6.00005 13.397C6.00143 13.2377 6.03453 13.0802 6.09742 12.9338C6.16032 12.7874 6.25173 12.655 6.36635 12.5443L11.1663 7.74428Z" />
  </svg>
);

export const CaretDownSmall = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8337 16.2557C12.6086 16.4807 12.3035 16.6071 11.9853 16.6071C11.6671 16.6071 11.3619 16.4807 11.1369 16.2557L6.33685 11.4557C6.11826 11.2294 5.99731 10.9263 6.00005 10.6116C6.00278 10.297 6.12898 9.99603 6.35147 9.77354C6.57396 9.55105 6.87494 9.42485 7.18957 9.42211C7.50421 9.41938 7.80733 9.54033 8.03365 9.75892L11.9853 13.7105L15.9369 9.75892C16.0476 9.64431 16.18 9.55289 16.3264 9.49C16.4728 9.42711 16.6302 9.39401 16.7896 9.39262C16.9489 9.39124 17.1069 9.4216 17.2544 9.48194C17.4019 9.54227 17.5359 9.63138 17.6485 9.74405C17.7612 9.85672 17.8503 9.99071 17.9106 10.1382C17.971 10.2857 18.0013 10.4437 18 10.603C17.9986 10.7623 17.9655 10.9198 17.9026 11.0662C17.8397 11.2126 17.7483 11.345 17.6337 11.4557L12.8337 16.2557Z" />
  </svg>
);

export const CaretRight = ({ className }) => (
  <svg className={className || ''} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.857 11.1663C16.082 11.3914 16.2084 11.6965 16.2084 12.0147C16.2084 12.3329 16.082 12.6381 15.857 12.8631L11.057 17.6631C10.8307 17.8817 10.5276 18.0027 10.213 18C9.89833 17.9972 9.59735 17.871 9.37486 17.6485C9.15237 17.426 9.02617 17.1251 9.02343 16.8104C9.0207 16.4958 9.14165 16.1927 9.36024 15.9663L13.3118 12.0147L9.36024 8.06315C9.24563 7.95245 9.15421 7.82004 9.09132 7.67363C9.02843 7.52723 8.99533 7.36976 8.99394 7.21043C8.99256 7.05109 9.02292 6.89308 9.08326 6.7456C9.14359 6.59813 9.2327 6.46414 9.34537 6.35147C9.45804 6.2388 9.59202 6.1497 9.7395 6.08936C9.88697 6.02902 10.045 5.99866 10.2043 6.00005C10.3637 6.00143 10.5211 6.03453 10.6675 6.09742C10.8139 6.16032 10.9463 6.25173 11.057 6.36635L15.857 11.1663Z" />
  </svg>
);

export const CaretLeft = ({ className }) => (
  <svg className={className || ''} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.14296 12.8337C7.91799 12.6086 7.79162 12.3035 7.79162 11.9853C7.79162 11.6671 7.91799 11.3619 8.14296 11.1369L12.943 6.33685C13.1693 6.11826 13.4724 5.99731 13.787 6.00005C14.1017 6.00278 14.4027 6.12898 14.6251 6.35147C14.8476 6.57396 14.9738 6.87494 14.9766 7.18957C14.9793 7.50421 14.8583 7.80733 14.6398 8.03365L10.6882 11.9853L14.6398 15.9369C14.7544 16.0476 14.8458 16.18 14.9087 16.3264C14.9716 16.4728 15.0047 16.6302 15.0061 16.7896C15.0074 16.9489 14.9771 17.1069 14.9167 17.2544C14.8564 17.4019 14.7673 17.5359 14.6546 17.6485C14.542 17.7612 14.408 17.8503 14.2605 17.9106C14.113 17.971 13.955 18.0013 13.7957 18C13.6363 17.9986 13.4789 17.9655 13.3325 17.9026C13.1861 17.8397 13.0537 17.7483 12.943 17.6337L8.14296 12.8337Z" />
  </svg>
);

export const CheckPlaceholder = () => (
  <svg width="22" height="23" className="lineart" viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1.32153" width="20" height="20" rx="10" strokeWidth="2" />
  </svg>
);

export const CheckActive = () => (
  <svg width="22" height="23" className="lineart" viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11.3215" r="11" />
    <path d="M6.86523 11.4458L10.0424 14.3771L15.135 8.26599" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const PlusCircle = () => (
  <svg className="lineart" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
