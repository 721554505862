import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getSelectedResourceName, getResourceSelectionEnabled, getAvailableAddonServiceIds,
  getSelectedServicesProps, getAutoResourceSelection, getTotalDuration, getTotalPrice
} from '../../helpers/booking';
import { useValueChanged } from '../../helpers/hooks';
import { capitalizeFirst } from '../../helpers/strings';
import { getBookingAddress, getMergedWebSettings, getPreference } from '../../helpers/settings';
import { getShowExVatInfo } from '../../helpers/payment';
import { web } from '../../helpers/preference-keys';
import { getPreviousStep } from '../../helpers/nav';
import { enableLocationSelection } from '../../config';
import { CaretUpSmall, CaretDownSmall } from '../base/images';
import ServiceSummaryServices from './service-summary-services';
import AddonService from './addon-service';
import Duration from '../intl/duration';
import Price from '../intl/price';
import Modal from '../base/modal';
import ResourceSelector from '../resource/resource-selector';
import DateTime from '../intl/date-time';
import ExVatInfo from '../base/ex-vat-info';

const ServiceSummary = ({
  slot, confirmation, resourceLabel, companyName, address, mobile, webSettings, location,
  selectedResourceName, resourceSelectionEnabled, selectedServicesProps, showExVatInfo,
  autoResourceSelection, services, totalDuration, totalPrice, availableServiceIds
}) => {
  const intl = useIntl();
  const { autoSelectFirstAvailableSlot } = webSettings;
  const showDateTime = slot && !autoSelectFirstAvailableSlot;
  const showResource = !autoResourceSelection || selectedResourceName;
  const hasTotalDuration = totalDuration > 0;
  const hasTotalPrice = totalPrice > 0;
  const hasMultipleServices = services?.size > 1;
  const resourceName = selectedResourceName || intl.formatMessage({ id: 'resource.showAll' });

  const [showSummary, setShowSummary] = useState(false);
  const [showAddons, setShowAddons] = useState(false);
  const [showResources, setShowResources] = useState(false);

  const toggleSummary = () => setShowSummary(value => !value);

  const className = classNames({
    'cb-service-summary': true,
    'cb-confirmation': confirmation,
    'cb-service-summary-sticky': mobile === true,
    'cb-expanded': showSummary && mobile,
    'cb-collapsed': !showSummary && mobile
  });

  const summaryBtnClassName = classNames({
    'cb-button': true,
    'icon-only': true
  });

  useValueChanged(location, () => setShowSummary(false));

  useEffect(() => {
    if (mobile) {
      document.body.classList.add('cb-service-summary-active');
    }
    return () => {
      document.body.classList.remove('cb-service-summary-active');
    };
  }, [mobile]);

  return (
    <>
      {showAddons && (
        <AddonService
          onContinue={() => setShowAddons(false)}
          onCancel={() => setShowAddons(false)}
        />
      )}

      {showResources && (
        <Modal
          isPlainModal
          size="medium"
          onClose={() => setShowResources(false)}
        >
          <ResourceSelector onSelect={() => setShowResources(false)} />
        </Modal>
      )}

      {!mobile && <div className="cb-nav-container" />}

      <div className={className}>
        <div className="cb-service-summary-header" onClick={mobile && toggleSummary}>
          <div className="cb-service-summary-text">
            <FormattedMessage id="service.summaryHeading" />
          </div>

          {mobile && (
            <button type="button" className={summaryBtnClassName}>
              {showSummary ? (
                <CaretDownSmall />
              ) : (
                <CaretUpSmall />
              )}
            </button>
          )}
        </div>
        <ul className="cb-service-summary-list">
          <ServiceSummaryServices />

          {availableServiceIds?.size > 0 && (
            <li className="cb-service-summary-cta">
              <div className="cb-service-summary-text">
                <button className="cb-button cb-button-tiny cb-button-outline" type="button" onClick={() => setShowAddons(true)}>
                  <FormattedMessage id="service.addonAddEdit" />
                </button>
              </div>
            </li>
          )}

          {hasTotalDuration && hasMultipleServices && (
            <li>
              <div className="cb-service-summary-text">
                <span className="cb-service-summary-label">
                  <FormattedMessage id="summary.duration" />
                </span>
                <Duration {...selectedServicesProps} />
              </div>
            </li>
          )}

          {hasTotalPrice && (
            <li className="cb-service-summary-total">
              <div className="cb-service-summary-text">
                <span className="cb-service-summary-label">
                  <FormattedMessage id="summary.price" />
                </span>
                <strong>
                  <Price {...selectedServicesProps} />
                </strong>
              </div>
            </li>
          )}

          {showDateTime && (
            <li>
              <div className="cb-service-summary-text">
                <span className="cb-service-summary-label">
                  <FormattedMessage id="summary.time" />
                </span>
                <DateTime slot={slot} showEndTime={hasTotalDuration} />
              </div>
            </li>
          )}
          {enableLocationSelection() && (
            <li>
              <div className="cb-service-summary-text">
                <span className="cb-service-summary-label">
                  <FormattedMessage id="summary.location" />
                </span>
                {companyName}
              </div>
            </li>
          )}
          {address && (
            <li>
              <div className="cb-service-summary-text">
                <span className="cb-service-summary-label">
                  <FormattedMessage id="summary.address" />
                </span>
                {address}
              </div>
            </li>
          )}
          {showResource && (
            <li>
              <div className="cb-service-summary-text">
                <span className="cb-service-summary-label">
                  {capitalizeFirst(resourceLabel || intl.formatMessage({ id: 'summary.resource' }))}
                </span>
                <div>
                  <span>{resourceName}</span>
                  {resourceSelectionEnabled && (
                    <button className="cb-button cb-button-tiny cb-button-outline" type="button" onClick={() => setShowResources(true)}>
                      <FormattedMessage id="service.edit" />
                    </button>
                  )}
                </div>
              </div>
            </li>
          )}
          {showExVatInfo && (
            <li>
              <ExVatInfo />
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const { booking, settings } = state;

  return {
    slot: booking.get('slot'),
    services: booking.get('services'),
    availableServiceIds: getAvailableAddonServiceIds(state),
    selectedServicesProps: getSelectedServicesProps(state),
    companyName: getPreference(settings, web.companyName),
    resourceLabel: getPreference(settings, web.resourceLabel),
    selectedResourceName: getSelectedResourceName(state),
    autoResourceSelection: getAutoResourceSelection(state),
    resourceSelectionEnabled: getResourceSelectionEnabled(state),
    webSettings: getMergedWebSettings(state),
    showExVatInfo: getShowExVatInfo(state),
    totalPrice: getTotalPrice(state),
    totalDuration: getTotalDuration(state),
    address: getBookingAddress(state),
    previousStep: getPreviousStep(state, props)
  };
};

export default withRouter(connect(mapStateToProps)(ServiceSummary));
