import moment from 'moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import { formattedPrice } from '../intl/price';

function getSwishMessage(intl, status) {
  switch (status) {
    case 'RP06':
      return intl.formatMessage({ id: 'error.swishAlreadyExists' });
    case 'ACMT03':
      return intl.formatMessage({ id: 'error.swishNotEnrolled' });
    case 'DECLINED':
      return intl.formatMessage({ id: 'error.paymentDeclined' });
    case 'ERROR':
    case 'CANCELLED':
    default:
      return null;
  }
}

function getBadRequestMessage(intl, { code, message, details }) {
  switch (code) {
    // Booking errors
    case 6:
      return intl.formatMessage({ id: 'error.refNotValid' });
    case 7:
      return intl.formatMessage({ id: 'error.refAlreadyUsed' });
    case 14:
      return intl.formatMessage({ id: 'error.alreadyBookedClass' });

    // Voucher errors
    case 1110:
      return intl.formatMessage({ id: 'error.voucherNotValid' });
    case 1117:
      return intl.formatMessage({ id: 'error.voucherNotFound' });
    case 1118:
      return intl.formatMessage({ id: 'error.voucherMinSpend' }, { amount: formattedPrice(intl, details?.value) });
    case 1119:
      return intl.formatMessage({ id: 'error.voucherBefore' }, { date: moment(details?.value).format('L') });
    case 1120:
      return intl.formatMessage({ id: 'error.voucherAfter' }, { date: moment(details?.value).format('L') });
    case 1121:
      return intl.formatMessage({ id: 'error.voucherMaxRedeems' });

    // Payment errors
    case 3000:
      return intl.formatMessage({ id: 'error.paymentProviderNA' });
    case 3004:
      return intl.formatMessage({ id: 'error.paymentNA' });

    // Swish errors
    case 3002: {
      return message?.split(',')
        .map(errorCode => getSwishMessage(intl, errorCode))
        .filter(message => message).join(' ');
    }

    default:
      return null;
  }
}

function getErrorMessage(intl, error, useSmsVerification) {
  const { response } = error;
  if (!response && error.message) {
    if (error.message === 'Network Error') {
      return intl.formatMessage({ id: 'error.maintenance' });
    }
    return error.message;
  }
  switch (response && response.status) {
    case 400: {
      const message = response.data && getBadRequestMessage(intl, response.data);
      return message || intl.formatMessage({ id: 'error.unknown' });
    }
    case 401:
    case 404:
      return intl.formatMessage({ id: 'error.unavailable' });
    case 402: {
      const stripeMessage = response.data && response.data.failureMessage;
      const swishMessage = response.data && getSwishMessage(intl, response.data.status);
      return stripeMessage || swishMessage || intl.formatMessage({ id: 'error.payment' });
    }
    case 403:
      return intl.formatMessage({ id: useSmsVerification ? 'error.wrongPin' : 'error.unknown' });
    case 410:
      return intl.formatMessage({ id: 'error.slotTaken' });
    case 418:
      return intl.formatMessage({ id: 'error.notAllowed' });
    case 429: {
      const mobile = response.data && response.data.key === 'mobile';
      return intl.formatMessage({ id: mobile ? 'error.alreadyBookedMob' : 'error.alreadyBookedPno' });
    }
    case 502:
    case 503:
      return intl.formatMessage({ id: 'error.maintenance' });
    default:
      return intl.formatMessage({ id: 'error.unknown' });
  }
}

const Error = ({ intl, error, useSmsVerification }) => (
  <section className="cb-error">
    {getErrorMessage(intl, error, useSmsVerification)}
  </section>
);

export default injectIntl(Error);
