import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getCurrentMappedServices, getCurrentResource, getTotalDuration } from '../helpers/booking';
import { formatPhoneNumber } from '../helpers/phone-number';
import { cardDescription } from '../helpers/strings';
import { getPaymentAmountExVat, getTotalAmount, getVatPctAmounts } from '../helpers/payment';
import { getBookingAddress, getMergedWebSettings, getPreference } from '../helpers/settings';
import { web } from '../helpers/preference-keys';
import { enableLocationSelection } from '../config';
import CustomFieldValue from './intl/custom-field-value';
import Duration from './intl/duration';
import Price from './intl/price';
import DateTime from './intl/date-time';

const Summary = ({
  resourceName, slot, mappedServices, customer, paymentMethod, paymentStatus, totalDuration,
  pricesExVat, paymentAmountIncVat, paymentAmountExVat, customFields, vatPctAmounts,
  showCustomer, confirmation, resourceLabel, customMessageLabel, companyName, address,
  useForeignPno, webSettings, paymentRequired, totalAmount, discountVoucher
}) => {
  const { vehicleRegNo, orgName, orgNo, name, phone, email, pno, foreignPno, note } = customer || {};
  const hasCompany = orgName || orgNo;
  const { autoSelectFirstAvailableSlot } = webSettings;
  const showDateTime = slot && !autoSelectFirstAvailableSlot;
  const hasTotalDuration = totalDuration > 0;

  return (
    <div className={confirmation ? 'cb-summary cb-confirmation' : 'cb-summary'}>
      <ul className="cb-summary-list">
        {showDateTime && (
          <li className="cb-summary-item">
            <div className="cb-summary-text">
              <span><FormattedMessage id="summary.time" /></span>
              <DateTime slot={slot} showEndTime={hasTotalDuration} />
            </div>
          </li>
        )}
        {enableLocationSelection() && (
          <li className="cb-summary-item">
            <div className="cb-summary-text">
              <span><FormattedMessage id="summary.location" /></span>
              {companyName}
            </div>
          </li>
        )}
        {address && (
          <li className="cb-summary-item">
            <div className="cb-summary-text">
              <span><FormattedMessage id="summary.address" /></span>
              {address}
            </div>
          </li>
        )}
        <li className="cb-summary-item">
          {resourceName && (
            <div className="cb-summary-text">
              <span>{resourceLabel || <FormattedMessage id="summary.resource" />}</span>
              {resourceName}
            </div>
          )}
        </li>
        <li className="cb-summary-item">
          <div className="cb-summary-text">
            <span><FormattedMessage id={mappedServices.size > 1 ? 'summary.services' : 'summary.service'} /></span>

            <div className="cb-summary-item-services">
              {mappedServices && mappedServices.valueSeq().map((service) => {
                const {
                  name, serviceId, serviceDuration, price, priceFrom, maxPrice,
                  maxDuration, webShowDuration, webShowPrice
                } = service.toObject();
                const showDetails = webShowDuration || webShowPrice;

                return (
                  <Fragment key={serviceId}>
                    {name}
                    {showDetails && (
                      <span className="cb-details">
                        {webShowDuration && <Duration duration={maxDuration || serviceDuration} />}
                        {webShowDuration && webShowPrice && ', '}
                        {webShowPrice && <Price price={maxPrice || price} priceFrom={priceFrom} />}
                      </span>
                    )}
                    <br />
                  </Fragment>
                );
              })}

            </div>
          </div>
        </li>
        {showCustomer && vehicleRegNo && (
          <li className="cb-summary-item">
            <div className="cb-summary-text">
              <span><FormattedMessage id="summary.vehicle" /></span>
              {vehicleRegNo}
            </div>
          </li>
        )}
        {showCustomer && hasCompany && (
          <li className="cb-summary-item">
            {orgName && (
              <div className="cb-summary-text">
                <span><FormattedMessage id="summary.company" /></span>
                {orgName}
              </div>
            )}
            {orgNo && (
              <div className="cb-summary-text">
                <span />
                {orgNo}
              </div>
            )}
          </li>
        )}
        {showCustomer && customer && (
          <li className="cb-summary-item">
            <div className="cb-summary-text">
              <span><FormattedMessage id="summary.customer" /></span>
              {name}
            </div>
            <div className="cb-summary-text">
              <span />
              {formatPhoneNumber(phone)}
            </div>
            {email && (
              <div className="cb-summary-text">
                <span />
                {email}
              </div>
            )}
            {pno && !useForeignPno && (
              <div className="cb-summary-text">
                <span />
                {pno}
              </div>
            )}
            {foreignPno && useForeignPno && (
              <div className="cb-summary-text">
                <span />
                {foreignPno}
              </div>
            )}
          </li>
        )}
        {showCustomer && customer && customer.custom && customFields && !customFields.isEmpty() && (
          <li className="cb-summary-item">
            {customFields.map((field) => {
              const { key, label } = field.toObject();
              const value = customer.custom[key];
              return value !== null && value !== undefined ? (
                <div className="cb-summary-text" key={key}>
                  <span>{label}</span>
                  <CustomFieldValue value={value} />
                </div>
              ) : null;
            })}
          </li>
        )}
        {showCustomer && note && (
          <li className="cb-summary-item">
            <div className="cb-summary-text">
              <span>{customMessageLabel || <FormattedMessage id="customer.note" />}</span>
              {note}
            </div>
          </li>
        )}
        {showCustomer && paymentMethod && (
          <li className="cb-summary-item">
            <div className="cb-summary-text">
              <span><FormattedMessage id="summary.card" /></span>
              {cardDescription(paymentMethod.card)}
            </div>
          </li>
        )}
        {showCustomer && paymentRequired && !discountVoucher && (
          <li className="cb-summary-item">
            {pricesExVat && (
              <div className="cb-summary-text">
                <span><FormattedMessage id="summary.priceExVat" /></span>
                <Price price={paymentAmountExVat} fractionDigits={2} />
              </div>
            )}
            {pricesExVat && Object.keys(vatPctAmounts).map((vatPct) => vatPctAmounts[vatPct] > 0 && (
              <div className="cb-summary-text">
                <span><FormattedMessage id="summary.vat" values={{ vatPct }} /></span>
                <Price price={vatPctAmounts[vatPct]} fractionDigits={2} />
              </div>
            ))}
            <div className="cb-summary-text">
              <span><FormattedMessage id={paymentStatus === 'Paid' ? 'summary.amountPaid' : 'summary.amountToPay'} /></span>

              <span className="cb-summary-payment-amount">
                <Price price={paymentAmountIncVat} fractionDigits={2} />
              </span>
            </div>
          </li>
        )}
        {showCustomer && paymentRequired && discountVoucher && (
          <li className="cb-summary-item">
            <div className="cb-summary-text">
              <span><FormattedMessage id={paymentStatus === 'Paid' ? 'summary.amountPaid' : 'summary.amountToPay'} /></span>

              <span className="cb-summary-payment-amount">
                <Price price={paymentAmountIncVat} fractionDigits={2} />{' '}

                <span className="cb-line-through">
                  <Price price={totalAmount} fractionDigits={2} />
                </span>
              </span>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { booking, settings, payment, customFields } = state;

  const slot = booking.get('slot');
  const resource = getCurrentResource(state);
  const resourceName = resource && resource.get('name');

  return {
    slot,
    resourceName: settings.get('resourceName') || resourceName,
    useForeignPno: booking.get('useForeignPno'),
    customer: booking.get('customer'),
    discountVoucher: payment.get('discountVoucher'),
    totalAmount: getTotalAmount(state),
    paymentMethod: payment.get('paymentMethod'),
    paymentStatus: booking.get('paymentStatus'),
    paymentRequired: payment.get('paymentRequired'),
    paymentAmountIncVat: booking.get('paymentAmountIncVat'),
    pricesExVat: settings.get('pricesExVat'),
    customFields: customFields.get('fields'),
    paymentAmountExVat: getPaymentAmountExVat(state),
    vatPctAmounts: getVatPctAmounts(state),
    companyName: getPreference(settings, web.companyName),
    resourceLabel: getPreference(settings, web.resourceLabel),
    customMessageLabel: getPreference(settings, web.customMessageLabel),
    mappedServices: getCurrentMappedServices(state),
    webSettings: getMergedWebSettings(state),
    totalDuration: getTotalDuration(state),
    address: getBookingAddress(state)
  };
};

export default connect(mapStateToProps)(Summary);
