import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import ServiceSummary from './service-summary';
import { breakpointTablet, isBrowser } from '../../env';

const ServiceSummaryContainer = ({ containerWidth, location }) => {
  if (containerWidth > breakpointTablet) {
    return <ServiceSummary />;
  }

  return isBrowser ? createPortal((
    <ServiceSummary mobile location={location} />
  ), document.body) : null;
};

const mapStateToProps = (state, props) => {
  const { app } = state;

  return {
    containerWidth: app.get('containerWidth'),
    location: props.location
  };
};

export default connect(mapStateToProps)(ServiceSummaryContainer);
