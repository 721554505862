import React from 'react';
import { FormattedTime } from 'react-intl';
import Price from '../intl/price';

const CalendarSlot = ({ onSelect, showExtended, time, resource, selected }) => {
  const parts = time.split(':');
  const price = resource && resource.get('price');
  const priceFrom = resource && resource.get('priceFrom');
  const date = new Date().setHours(parts[0], parts[1]);
  return (
    <a href="#" className={selected ? 'cb-slot cb-slot-selected' : 'cb-slot'} onClick={onSelect}>
      <strong><FormattedTime value={date} /></strong>
      {showExtended && <span className="cb-slot-resource">{resource && resource.get('name')}</span>}
      {showExtended && price > 0 && <span className="cb-slot-price"><Price price={price} priceFrom={priceFrom} /></span>}
    </a>
  );
};

export default CalendarSlot;
