import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getFirstStep, Step } from '../../helpers/nav';
import ResourceSelectorContainer from '../resource/resource-selector-container';
import ServiceSummaryContainer from '../service/service-summary-container';

const Sidebar = () => {
  const firstStep = useSelector(getFirstStep);

  const serviceSummaryPath = `/:step(${Step.Calendar}|${Step.Customer}|${Step.Verify})${firstStep === Step.Calendar ? '?' : ''}`;
  const resourceSelectorPath = `/:step(${Step.Service})${firstStep === Step.Service ? '?' : ''}`;

  return (
    <div className="cb-layout-sidebar">
      <Switch>
        <Route exact path={serviceSummaryPath} component={ServiceSummaryContainer} />
        <Route exact path={resourceSelectorPath} component={ResourceSelectorContainer} />
      </Switch>
    </div>
  );
};

export default Sidebar;
