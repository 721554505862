import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CaretLeft } from '../base/images';
import Modal from '../base/modal';
import Error from '../base/error';

const PaymentError = ({ error, onClose }) => {
  const handleClose = (ev) => {
    ev.preventDefault();
    onClose();
  };

  return (
    <Modal
      size="small"
      title={(<FormattedMessage id="error.paymentHeading" />)}
      onClose={handleClose}
    >

      <Error error={error} />

      <button type="button" onClick={handleClose} className="cb-button">
        <CaretLeft className="left" />
        <FormattedMessage id="error.back" />
      </button>
    </Modal>
  );
};

export default PaymentError;
