import React from 'react';
import { Prompt } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Step } from '../../helpers/nav';

const VerifyPrompt = ({ intl }) => {
  return (
    <Prompt
      message={(location) => {
        if (location.pathname.indexOf(Step.Confirmation) !== -1) {
          return true;
        }
        return intl.formatMessage({ id: 'verify.beforeUnload' });
      }}
    />
  );
};

export default injectIntl(VerifyPrompt);
