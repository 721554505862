import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { CaretRight, CaretUpSmall } from '../base/images';
import DurationAndPrice from './duration-and-price';

const ServiceItemCta = ({
  service,
  available,
  bookable,
  onSelect,
  selected,
  multipleServices
}) => {
  const { addon } = service.toObject();

  const btnClassName = classNames({
    'cb-button': true,
    'cb-button-success': selected,
    'cb-button-success-outline': !selected
  });

  const getButtonLabel = () => {
    if (selected) {
      return 'service.selected';
    }
    if (addon) {
      return multipleServices ? 'service.add' : 'service.select';
    }
    return multipleServices ? 'service.select' : 'service.book';
  };

  return (
    <div className="cb-service-item-cta">
      {available && !bookable && <span className="cb-not-bookable"><FormattedMessage id="service.notBookable" /></span>}

      {available && bookable && (
        <button type="button" className={btnClassName} onClick={bookable ? onSelect : null}>
          <FormattedMessage id={getButtonLabel()} />
        </button>
      )}
    </div>
  );
};

const ServiceItem = (props) => {
  const {
    service, selected, available, bookable, multipleServices,
    alwaysShowDescription
  } = props;
  const intl = useIntl();
  const descriptionElement = useRef();
  const [showDescription, setShowDescription] = useState(alwaysShowDescription);
  const [descriptionOverflow, setDescriptionOverflow] = useState(false);
  const containerWidth = useSelector(state => state.app.get('containerWidth'));
  const { serviceId, name, description } = service.toObject();
  const hasDescription = description?.length > 0;

  const hasDescriptionOverflow = () => {
    const element = descriptionElement.current;
    return hasDescription && element && (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth);
  };

  const resetDescriptionOverflow = () => {
    setShowDescription(false);
    setTimeout(() => {
      setDescriptionOverflow(hasDescriptionOverflow());
    }, 0);
  };

  useEffect(() => {
    if (!alwaysShowDescription) {
      resetDescriptionOverflow();
    }
  }, [containerWidth]);

  const toggleDescription = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setShowDescription(showDescription => !showDescription);
  };

  const className = classNames({
    'cb-service-item': true,
    selected: multipleServices && selected,
    available,
    bookable
  });

  const descriptionClassName = classNames({
    'cb-service-item-description': true,
    'cb-expanded': hasDescription && showDescription && !alwaysShowDescription,
    'cb-collapsed': hasDescription && !showDescription && !alwaysShowDescription
  });

  const showMoreText = showDescription
    ? intl.formatMessage({ id: 'service.showLess' })
    : intl.formatMessage({ id: 'service.showMore' });

  return (
    <div id={`cb-service-${serviceId}`} className={className}>
      <div className="cb-service-item-container">
        <div className="cb-service-item-info">

          <div className="cb-service-item-header">
            <span>
              <strong className="cb-service-details-title">{name}</strong>

              <label className="cb-service-details-label">
                <DurationAndPrice service={service} />
              </label>
            </span>

            <ServiceItemCta {...props} />
          </div>

          {hasDescription && (
            <div className={descriptionClassName}>
              <p ref={descriptionElement}>
                {description}
              </p>

              {descriptionOverflow && !alwaysShowDescription && (
                <p className="cb-service-details-readmore">
                  <a href="#" onClick={toggleDescription}>
                    {showDescription ? <CaretUpSmall /> : <CaretRight />}
                    <span>{showMoreText}</span>
                  </a>
                </p>
              )}
            </div>
          )}
        </div>

        {!service.get('addon') && <ServiceItemCta {...props} />}
      </div>
    </div>
  );
};

export default ServiceItem;
