import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { fetchRefData } from '../../actions/ref-data';
import { setBookingLocation } from '../../actions/booking';
import { getNextStep } from '../../helpers/nav';
import { enableLocationSelection } from '../../config';
import LocationItem from './location-item';

class Location extends React.Component {
  locationSelected = (location) => {
    this.props.setBookingLocation(location);
    this.props.fetchRefData()
      .then(() => this.props.history.push(this.props.nextStep));
  };

  render() {
    const { locations, nextStep } = this.props;

    if (!enableLocationSelection()) {
      return <Redirect to={nextStep} />;
    }

    return (
      <>
        {locations.valueSeq().sortBy(l => l.get('order')).map((location) => {
          return (
            <LocationItem
              key={location.get('stableId')}
              onSelect={() => this.locationSelected(location)}
              location={location}
            />
          );
        })}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { locations } = state;

  return {
    locations,
    nextStep: getNextStep(state, props)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBookingLocation: (location) => {
      dispatch(setBookingLocation(location));
    },
    fetchRefData: () => {
      return dispatch(fetchRefData());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
