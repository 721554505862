import { injectIntl } from 'react-intl';
import { isSet } from '../../helpers/strings';
import config from '../../config';

export function formattedPrice(intl, price, priceFrom, fractionDigits) {
  const formattedNumber = intl.formatNumber(price, {
    style: 'currency',
    currency: config.currency,
    minimumFractionDigits: fractionDigits || 0,
    maximumFractionDigits: fractionDigits || 0
  });

  if (price === 0) {
    return intl.formatMessage({ id: 'free' });
  }
  if (priceFrom) {
    return intl.formatMessage({ id: 'fromPrice' }, { price: formattedNumber });
  }
  return formattedNumber;
}

export function formattedPriceRange(intl, price, minPrice, maxPrice, priceFrom, fractionDigits) {
  if (!isSet(price) && !isSet(minPrice) && !isSet(maxPrice)) {
    return null;
  }
  const showPriceFrom = priceFrom || minPrice !== maxPrice;
  return formattedPrice(intl, isSet(price) ? price : minPrice, showPriceFrom, fractionDigits);
}

const Price = ({ intl, price, minPrice, maxPrice, priceFrom, fractionDigits }) => {
  return formattedPriceRange(intl, price, minPrice, maxPrice, priceFrom, fractionDigits);
};

export default injectIntl(Price);
