import React from 'react';
import Logo from './logo';

const Footer = () => {
  return (
    <div className="cb-footer">
      <Logo />
    </div>
  );
};

export default Footer;
