import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { web } from '../../helpers/preference-keys';
import { getPreference } from '../../helpers/settings';
import { getResourceList, getResourceSelectionEnabled, getSelectedResourceIds } from '../../helpers/booking';
import { getResourceText, getSelectMessage, getSelectedResourceId, isSingleResouce } from '../../helpers/resource';
import { setBookingResource } from '../../actions/booking';
import { CheckPlaceholder, CheckActive } from '../base/images';

class ResourceSelectorItems extends React.Component {
  state = {
    hasSelectedResource: false
  };

  resourceSelected = (ev) => {
    const { resources, onSelect } = this.props;
    const resourceId = parseInt(ev.target.value, 10);
    const resource = resources.find(r => r.get('id') === resourceId);
    this.props.setBookingResource(resource || null);
    this.setState({ hasSelectedResource: true });

    if (onSelect) {
      setTimeout(() => onSelect(), 100);
    }
  };

  render() {
    const { hasSelectedResource } = this.state;
    const {
      resourceList, selectedResourceIds, resourceLabel, resourceSelectionEnabled,
      intl, showDetails
    } = this.props;

    if (!resourceSelectionEnabled) {
      return null;
    }

    const defaultResourceId = hasSelectedResource ? 'ALL' : '';
    const selectedResourceId = getSelectedResourceId(resourceList, selectedResourceIds) || defaultResourceId;

    const defaultActiveResource = selectedResourceId === defaultResourceId;
    const selectMessage = getSelectMessage(intl, resourceLabel);
    const showAllMessage = intl.formatMessage({ id: 'resource.showAll' });

    return (
      <ul className="cb-resource-selector">
        <li className="cb-resource-selector-header">
          <div className="cb-resource-text">
            {selectMessage}
          </div>
        </li>

        {!isSingleResouce(resourceList) && (
          <li className={defaultActiveResource ? 'active' : undefined} onClick={this.resourceSelected}>
            <div className="cb-resource-text">
              {showAllMessage}
            </div>

            {defaultActiveResource ? <CheckActive /> : <CheckPlaceholder />}
          </li>
        )}

        {resourceList.map((resource) => {
          const resourceId = resource.get('id');
          const disabled = !resource.get('available') || !resource.get('bookable');
          const activeResource = selectedResourceId === resourceId;

          const className = classNames({
            active: activeResource,
            disabled
          });

          return (
            <li
              className={className}
              key={resourceId}
              value={resourceId}
              onClick={!disabled ? this.resourceSelected : undefined}
            >
              <div className="cb-resource-text">
                {getResourceText(resource, showDetails, intl)}

                {resource.get('title') ? (
                  <div className="cb-resource-text-title">
                    {resource.get('title')}
                  </div>
                ) : null}
              </div>

              {activeResource ? <CheckActive /> : <CheckPlaceholder />}
            </li>
          );
        })}
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  const { resources, settings } = state;

  return {
    resources,
    resourceList: getResourceList(state),
    selectedResourceIds: getSelectedResourceIds(state),
    resourceSelectionEnabled: getResourceSelectionEnabled(state),
    resourceLabel: getPreference(settings, web.resourceLabel)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBookingResource: (resource) => {
      dispatch(setBookingResource(resource));
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ResourceSelectorItems));
