import React from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import { capitalizeFirst } from '../../helpers/strings';

const DateTime = ({ intl, slot, showEndTime }) => {
  const { date, time, length } = slot;
  const dateTime = moment(`${date}T${time}`);
  const endTime = dateTime.clone().add(length, 'minutes');

  const slotDate = intl.formatMessage({ id: 'slotDate' }, {
    day: capitalizeFirst(dateTime.format('dddd')),
    date: dateTime.format('LL')
  });
  const slotTime = showEndTime
    ? intl.formatMessage({ id: 'slotTimeStartEnd' }, {
      start: dateTime.format('LT'),
      end: endTime.format('LT')
    }) : intl.formatMessage({ id: 'slotTimeStart' }, {
      start: dateTime.format('LT')
    });

  return (
    <div>
      {slotDate}<br />
      {slotTime}
    </div>
  );
};

export default injectIntl(DateTime);
