import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CheckMarkThankYou } from './images';
import { getIsConfirmationStep, getStepHeading } from '../../helpers/nav';

const Header = ({ stepHeading, isConfirmation }) => {
  const className = classNames({
    'cb-header': true,
    'cb-confirmation-header': isConfirmation
  });

  return (
    <div className={className}>
      {isConfirmation && (
        <div className="cb-confirmation-check">
          <CheckMarkThankYou />
        </div>
      )}
      <h2><FormattedMessage id={stepHeading} /></h2>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    stepHeading: getStepHeading(state, props),
    isConfirmation: getIsConfirmationStep(state, props)
  };
};

export default injectIntl(connect(mapStateToProps)(Header));
