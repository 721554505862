import { formattedPrice, formattedPriceRange } from '../components/intl/price';
import { formattedDuration, formattedDurationRange } from '../components/intl/duration';

export function getSelectMessage(intl, resourceLabel) {
  const defaultResourceLabel = intl.formatMessage({ id: 'summary.resource' }).toLowerCase();
  const selectLabel = { label: resourceLabel ? resourceLabel.toLowerCase() : defaultResourceLabel };

  return intl.formatMessage({ id: 'resource.select' }, selectLabel);
}

export const getResourceText = (resource, showDetails, intl) => {
  const { name, price, minPrice, maxPrice, priceFrom, duration, minDuration, maxDuration } = resource.toObject();

  let resourceText = name;
  if (!showDetails) {
    return resourceText;
  }

  if (price > 0) {
    resourceText += `, ${formattedPrice(intl, price, priceFrom)}`;
  } else if (maxPrice > 0) {
    resourceText += `, ${formattedPriceRange(intl, null, minPrice, maxPrice, priceFrom)}`;
  }
  if (duration > 0) {
    resourceText += ` (${formattedDuration(intl, duration)})`;
  } else if (maxDuration > 0) {
    resourceText += ` (${formattedDurationRange(intl, null, minDuration, maxDuration)})`;
  }
  return resourceText;
};

export function isSingleResouce(resourceList) {
  const availableResources = resourceList.filter(r => r.get('available'));

  return availableResources.size === 1;
}

export function getSelectedResourceId(resourceList, selectedResourceIds) {
  const availableResources = resourceList.filter(r => r.get('available'));
  const singleResourceId = availableResources.size === 1 ? availableResources.first().get('id') : null;

  return selectedResourceIds
    ? resourceList.map(r => r.get('id')).find(id => selectedResourceIds.indexOf(id) !== -1)
    : singleResourceId;
}
